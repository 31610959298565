import { fabric } from 'fabric';

class FreeDrawCommand {
  constructor(e) {
    this.canvas = e.data.canvas;
    this.params = e.data.params;
    this.points = e.data.points;
    this.cid = e.data.cid;
    this.commandName = 'FreeDraw';
  }

  saveState() {
  }

  undo() {
    this.canvas.remove(this.object);
  }

  execute() {
    this.object = new fabric.Path(this.points, this.params);

    this.canvas.add(this.object);
    this.canvas.bringToFront(this.object);
    this.canvas.renderAll();
  }

  export() {
    const data = {
      params:  this.params,
      points:  this.points,
      command: this.commandName,
      cid:     this.cid,
    };

    return data;
  }
}

export default FreeDrawCommand;
