<template>
  <v-container
    class="tabBackground"
  >
    <v-row>
      <v-col cols="1" />
      <v-col cols="10">
        <v-data-table
          :headers="headers"
          :items="diagnostics"
          :loading="loading"
          :options.sync="options"
          class="elevation-1 settingsTable"
        >
          <template
            #item="{item}"
          >
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ filterTypeId(item.type_id) }}</td>
              <td>{{ item.message }}</td>
              <td>{{ getCreateTime(item.message_time) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          v-if="diagnostics.length"
          class="secondary white--text v-btn"
          @click="exportDiagnostic"
        >
          {{ $t('systemDiagnostics.export') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { saveAs } from 'file-saver/FileSaver';
import jsonToCsvService from '../../js/services/jsonToCsvService';
import systemDiagnosticsService from '../../js/services/systemDiagnosticsService';
import config from '../../js/config';

export default {
  name: 'SystemDiagnostics',
  data() {
    return {
      searchText: '',
      headers:    [
        {
          text:     this.$t('systemDiagnostics.diagnosticsTable.id'),
          sortable: false,
          align:    'center',
          value:    'id',
        },
        {
          text:     this.$t('systemDiagnostics.diagnosticsTable.type'),
          sortable: false,
          align:    'center',
          value:    'type_id',
        },
        {
          text:     this.$t('systemDiagnostics.diagnosticsTable.message'),
          sortable: false,
          align:    'center',
          value:    'message',
        },
        {
          text:     this.$t('systemDiagnostics.diagnosticsTable.messageTime'),
          sortable: false,
          align:    'center',
          value:    'message_time',
        },
      ],
      options: {
        page:         1,
        itemsPerPage: 10,
        sortBy:       [this.$t('systemDiagnostics.diagnosticsTable.id')],
      },
      diagnostics: [],
      dialog:      false,
      loading:     'secondary',
    };
  },
  watch: {
    /**
     * Update page
     */
    options: {
      handler() {
        this.debouncedUpdate();
      },
      deep: true,
    },
  },
  created() {
    this.getDiagnosticsCount();
    this.debouncedUpdate = _.debounce(this.updateDiagnostics, 200);
  },
  methods: {
    async getDiagnosticsCount() {
      this.pageCount = Math.ceil(
        (await systemDiagnosticsService.getDiagnosticsCount()) / this.options.itemsPerPage,
      );
    },

    /**
     * Load all system diagnostics.
     */
    async updateDiagnostics() {
      this.loading = 'secondary';
      this.diagnostics = await systemDiagnosticsService
        .getDiagnosticsList(this.options.page, this.options.itemsPerPage);

      // console.log(`${JSON.stringify(this.diagnostics)}`);

      this.loading = false;
    },

    /**
     * Filter type id.
     *
     * @param {integer} typeId - Type Id.
     *
     * @return {string}
     */
    filterTypeId(typeId) {
      const types = this.$t('systemDiagnostics.diagnosticsTable.types');

      return typeId && types[typeId] ? types[typeId] : '';
    },

    /**
     * Export system diagnostics to csv file.
     */
    exportDiagnostic() {
      const header = this.headers.map(currentValue => currentValue.text);
      const data = this.diagnostics.map(currentValue => [
        currentValue.id,
        this.filterTypeId(currentValue.type_id),
        currentValue.message,
        currentValue.message_time,
      ]);

      saveAs(
        new Blob([jsonToCsvService.parseToCsv(header, data)], { type: 'text/csv' }),
        jsonToCsvService.getFileName(this.$t('systemDiagnostics.export')),
      );
    },
    getCreateTime(dateCreated) {
      return this.$moment(dateCreated).format(config.dateTimeFormat);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../css/components/_table';
  @import '../../css/components/_settingsTab';
</style>
