<template>
  <v-container
    class="tabBackground"
  >
    <v-row>
      <v-col cols="1" />
      <v-col cols="10">
        <v-data-table
          :headers="headers"
          :items="logs"
          :loading="loading"
          :options.sync="options"
          :server-items-length="pageCount"
          class="elevation-1 settingsTable"
        >
          <template
            #item="{item}"
          >
            <tr>
              <td>{{ item.userId }}</td>
              <td>{{ item.newData }}</td>
              <td>{{ item.changeTimestamp }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          v-if="logs.length"
          class="secondary white--text v-btn"
          @click="exportAuditLog"
        >
          {{ $t('auditLog.export') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { saveAs } from 'file-saver/FileSaver';
import jsonToCsvService from '../../js/services/jsonToCsvService';
import auditLogService from '../../js/services/auditLogService';

export default {
  name: 'AuditLog',
  data() {
    return {
      loading:    'secondary',
      searchText: '',
      headers:    [
        {
          text:     this.$t('auditLog.logTable.username'),
          sortable: false,
          align:    'center',
          value:    'username',
        },
        {
          text:     this.$t('auditLog.logTable.message'),
          sortable: false,
          align:    'center',
          value:    'new_data',
        },
        {
          text:     this.$t('auditLog.logTable.date'),
          sortable: false,
          align:    'center',
          value:    'change_timestamp',
        },
      ],
      options: {
        page:         1,
        itemsPerPage: 10,
      },
      logs:      [],
      dialog:    false,
      pageCount: 1,
    };
  },
  watch: {
    /**
     * Watch options to update page
     */
    options: {
      handler() {
        this.debouncedUpdate();
      },
      deep: true,
    },
  },
  created() {
    this.getLogCount();
    this.debouncedUpdate = _.debounce(this.updateLogs, 200);
  },
  methods: {

    /**
     * Load all audit logs.
     */
    async updateLogs() {
      this.loading = 'secondary';
      this.logs = await auditLogService.getLogsList(this.options.page, this.options.itemsPerPage);
      this.loading = false;
      // console.log('updateLogs1');
    },

    async getLogCount() {
      this.pageCount = Math.ceil(
        (await auditLogService.getLogsCount()) / this.options.itemsPerPage,
      );
    },

    /**
     * Export audit log to csv file.
     */
    exportAuditLog() {
      const header = this.headers.map(currentValue => currentValue.text);
      const data = this.logs.map(currentValue => [
        currentValue.userName,
        currentValue.new_data,
        currentValue.change_timestamp,
      ]);

      saveAs(
        new Blob([jsonToCsvService.parseToCsv(header, data)], { type: 'text/csv' }),
        jsonToCsvService.getFileName(this.$t('auditLog.export')),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../css/variables';
  @import '../../css/components/_settingsTab';
  @import '../../css/components/_table';

  .settingsTable {
    table {

      tr:nth-child(2n) td {
        background-color: $grey-lighten-1;
      }

      tr:hover td {
        background-color: $grey-5;
      }

      tr[active] td {
        background: $grey-4;
      }
    }
  }
</style>
