import { fabric } from 'fabric';

class RectCommand {
  constructor(e) {
    this.canvas = e.data.canvas;
    this.params = e.data.params;
    this.cid = e.data.cid;
    this.commandName = 'Rectangle';
  }

  saveState() {
  }

  undo() {
    this.canvas.remove(this.object);
  }

  execute() {
    this.object = new fabric.Rect(this.params);

    this.canvas.add(this.object);
    this.canvas.bringToFront(this.object);
    this.canvas.renderAll();
  }

  export() {
    const data = {
      params:  this.params,
      command: this.commandName,
      cid:     this.cid,
    };

    return data;
  }
}

export default RectCommand;
