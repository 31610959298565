<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="test-h6 white--text primary">
          {{ $t('accountPage.captures.deleteTitle') }}
        </v-card-title>
        <v-card-text>
          <span
            v-if="error"
            class="error--text"
          >
            {{ error }}
          </span>
          <span v-else>
            {{ $t('accountPage.captures.deleteText') }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="!error"
            color="secondary white--text v-btn"
            @click="deleteCapture"
          >
            {{ $t('globalActions.delete') }}
          </v-btn>
          <v-btn
            color="gray darken-3"
            @click="close"
          >
            {{ $t('globalActions.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import capturesService from '../../js/services/capturesService';

export default {
  name:  'AccountCaptureDelete',
  props: {
    dialog: {
      type:    Boolean,
      default: false,
    },
    capture: {
      type:    Object,
      default: () => {},
    },
  },
  data() {
    return {
      error:      null,
      showDialog: false,
    };
  },
  watch: {
    dialog(val) {
      this.showDialog = val;
    },
  },
  methods: {
    /**
     * Delete capture.
     */
    async deleteCapture() {
      try {
        await capturesService.delete(this.capture.id);
        this.$emit('ok');
        this.close();
      } catch (error) {
        this.setDeleteError(error);
      }
    },

    /**
     * Launch when capture not deleted.
     *
     * @param {Object} error - Error.
     */
    setDeleteError(error) {
      if (error.response && error.response.data) {
        this.error = error.response.data;
      } else {
        this.error =  this.$t('accountPage.captures.deleteError');
      }
    },

    /**
     * Close dialog.
     */
    close() {
      this.showDialog = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../css/variables';

  .title {
    background-color: $blue-title;
  }
</style>
