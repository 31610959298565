<template>
  <v-container class="grey lighten-2">
    <v-row>
      <v-col cols="1" />
      <v-col cols="10"
             class="d-flex justify-end"
      >
        <v-btn
          color="secondary"
          class="mx-2"
          @click="openAddDialog(true)"
        >
          {{ $t('accountDocumentFieldsTab.addBtn') }}
        </v-btn>
        <v-btn
          class="mx-2"
          :disabled="!selectedField.length"
          @click="openAddDialog(false)"
        >
          {{ $t('accountDocumentFieldsTab.editBtn') }}
        </v-btn>
        <v-btn
          class="mx-2"
          :disabled="!selectedField.length"
          @click="openDeleteDialog()"
        >
          {{ $t('accountDocumentFieldsTab.deleteBtn') }}
        </v-btn>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-row>
      <v-col cols="1" />
      <v-col cols="10">
        <v-data-table
          v-model="selectedField"
          :headers="headers"
          :items="fields"
          :loading="loading"
          item-key="id"
          show-select
          single-select
        />
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <v-form>
        <v-card>
          <v-card-title class="text-h6 white--text primary">
            <span class="text-h5">
              {{ addMode ?
                $t('accountDocumentFieldsTab.addField.title') :
                $t('accountDocumentFieldsTab.editField.title')
              }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="newField.key"
                  required
                  :label="$t('accountDocumentFieldsTab.addField.key')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="newField.description"
                  required
                  :label="$t('accountDocumentFieldsTab.addField.description')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-switch
                  v-model="newField.visible"
                  color="secondary"
                  :label="$t('accountDocumentFieldsTab.addField.visible')"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="secondary"
              @click="submitField()"
            >
              {{ addMode ?
                $t('accountDocumentFieldsTab.addField.add') :
                $t('accountDocumentFieldsTab.editField.save') }}
            </v-btn>
            <v-btn @click="cancel()">
              {{ $t('accountDocumentFieldsTab.addField.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title class="text-h6 white--text primary">
          <span class="text-h5">
            {{ $t('accountDocumentFieldsTab.delete.title') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <span class="text-h5">
                {{ $t('accountDocumentFieldsTab.delete.question') }}
              </span>
            </v-col>
          </v-row>
          <v-row v-if="error">
            <v-col>
              <span class="text-h5">
                {{ error }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="secondary"
            @click="deleteField()"
          >
            {{ $t('accountDocumentFieldsTab.delete.ok') }}
          </v-btn>
          <v-btn @click="cancel()">
            {{ $t('accountDocumentFieldsTab.delete.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import accountDocumentFieldsService from '../../js/services/accountDocumentFieldsService';

export default {
  name: 'AccountDocumentFields',
  data() {
    return {
      fields:        [],
      loading:       'secondary',
      selectedField: [],
      dialog:        false,
      deleteDialog:  false,
      addMode:       false,
      error:         null,
      newField:      {
        key:         '',
        description: '',
        visible:     true,
      },
      headers: [
        {
          text:  this.$t('accountDocumentFieldsTab.headers.id'),
          align: 'center',
          value: 'id',
        },
        {
          text:  this.$t('accountDocumentFieldsTab.headers.key'),
          align: 'center',
          value: 'key',
        },
        {
          text:  this.$t('accountDocumentFieldsTab.headers.description'),
          align: 'center',
          value: 'description',
        },
        {
          text:  this.$t('accountDocumentFieldsTab.headers.visible'),
          align: 'center',
          value: 'visible',
        },
      ],
    };
  },
  async mounted() {
    await this.loadFields();
  },
  methods: {
    async loadFields() {
      this.loading = 'secondary';
      await accountDocumentFieldsService.loadAccountDocumentsFields();

      this.fields = await accountDocumentFieldsService.getAllAccountDocumentFields();
      this.loading = false;
    },
    async submitField() {
      try {
        if (this.addMode) {
          await accountDocumentFieldsService.addAccountDocumentField(this.newField);
        } else {
          await accountDocumentFieldsService.updateAccountDocumentField(this.newField);
        }
      } catch (error) {
        this.error = error.data ? error.data : this.$t('accountDocumentFieldsTab.error');
      }
      this.dialog = false;
      await this.loadFields();
    },
    cancel() {
      this.dialog = false;
      this.deleteDialog = false;
    },
    openAddDialog(addMode) {
      this.error = null;
      this.addMode = addMode;
      this.dialog = true;
      if (!this.addMode) {
        this.newField = JSON.parse(JSON.stringify(this.selectedField[0]));
      } else {
        this.newField = { key: '', description: '', visible: true };
      }
    },
    openDeleteDialog() {
      this.error = null;
      this.deleteDialog = true;
    },
    async deleteField() {
      try {
        await accountDocumentFieldsService.deleteAccountDocumentField(this.selectedField[0].id);
      } catch (error) {
        this.error = error.data ? error.data : this.$t('accountDocumentFieldsTab.error');
      }
      this.deleteDialog = false;
      await this.loadFields();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../css/variables';
  @import '../../css/components/_settingsTab';

  .title {
    background-color: $blue-title;
  }
</style>
