import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import store from '../store/store';

import {
  ADD_TO_DERMAID_STUDIO,
  CLEAR_IMAGES_MUTATION,
  SET_DERMAID_ENABLED,
  REMOVE_IMAGES_FROM_DERMAID_STUDIO,
  ADD_PAGES_TO_DERMAID_STUDIO,
} from '../store/mutations';
import {
  DERMAID_ENABLED_GETTER,
  DERMAID_STUDIO_IMAGES,
  CAPTURES_VIEW_GETTER,
  DERMAID_STUDIO_PAGES,
} from '../store/getters';

export default {
  $store: store,
  /**
   * Mutations from Vuex Store
   */
  ...mapMutations([
    ADD_TO_DERMAID_STUDIO,
    CLEAR_IMAGES_MUTATION,
    SET_DERMAID_ENABLED,
    ADD_PAGES_TO_DERMAID_STUDIO,
    REMOVE_IMAGES_FROM_DERMAID_STUDIO,

  ]),

  /**
   * Getters from Vuex Storage
   */
  ...mapGetters([
    DERMAID_ENABLED_GETTER,
    DERMAID_STUDIO_IMAGES,
    CAPTURES_VIEW_GETTER,
    DERMAID_STUDIO_PAGES,
  ]),

  /**
   * Add selected images to Dermaid studio.
   */
  addToDermaidStudio(images) {
    this[ADD_TO_DERMAID_STUDIO](images);
  },

  /**
   * Returns images from Dermaid studio.
   *
   * @return {Array}
   */
  getDermaidStudioImages() {
    return this[DERMAID_STUDIO_IMAGES]();
  },

  /**
     * Remove all images from Dermaid studio.
     */
  removeAllImages() {
    this[CLEAR_IMAGES_MUTATION]();
  },

  async getPresetFrames() {
    try {
      const response = await Vue.axios.get('/api/dermaid/presets');

      return response.data;
    } catch (error) {
    // eslint-disable-next-line no-console
      console.error('Error getting Dermaid preset frames:', error);
      throw error;
    }
  },

  /**
   * Check if Dermaid is enabled.
   */
  async fetchDermaidEnabled() {
    try {
      const response = await Vue.axios.get('/api/dermaid/enabled');

      //  console.log(response.data);

      return response.data;
    } catch (error) {
    // eslint-disable-next-line no-console
      console.error('Error checking if Dermaid is enabled:', error);

      return false;
    }
  },

  async getPagesByProjectId(projectId) {
    const url = `api/dermaid/${projectId}/pages`;

    try {
      const response = await Vue.axios.get(url);

      return response.data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error getting dermaid pages: ', err);

      throw err;
    }
  },

  /**
   * Add selected images to dermaid studio.
   */
  addPagesToDermaidStudio(pages) {
    this[ADD_PAGES_TO_DERMAID_STUDIO](pages);
  },

  getPages() {
    return this[DERMAID_STUDIO_PAGES]();
  },

  /**
   * Remove images from dermaid studio by capture.
   *
   * @param {number} id - Capture identifier.
   */
  removeFromDermaidStudio(image) {
    this[REMOVE_IMAGES_FROM_DERMAID_STUDIO](image);
  },

  /**
   * Set Dermaid enabled status.
   */
  async setDermaidEnabled(enabled) {
    this[SET_DERMAID_ENABLED](enabled);
  },

  /**
   * Create Dermaid project.
   */
  async createDermaidProject(captureInfo) {
    try {
      const projectResponse = await Vue.axios.post('/api/image_studio?projectType=dermaid', captureInfo);

      return projectResponse.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error creating Dermaid project:', error);
      throw error;
    }
  },

  /**
    * Fetch project info by project ID
    * @param projectId
    * @returns {Promise}
   */
  async fetchProjectDownloadInfo(projectId) {
    try {
      const response = await Vue.axios.get(`/api/image_studio/${projectId}/info`);

      return response.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error fetching download info for project ID ${projectId}:`, error);
      throw error;
    }
  },

  async fetchAnalysisByPageId(pageId) {
    try {
      const response = await Vue.axios.get(`/api/dermaid/${pageId}`);

      return response.data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error fetching analysis by pageId:', err);
      throw  err;
    }
  },

  async fetchAnalysis(projectId) {
    const response = await Vue.axios.get(`/api/dermaid/${projectId}`);

    return response.data;
  },

  async fetchProjectMedia(projectId) {
    const response = await Vue.axios.get(`/api/image_studio/project_media/${projectId}`);

    return response.data;
  },

  /**
 * Delete a project.
 * @param {int} id - Project id.
 * @param {string} projectType - Project type.
 */
  async deleteProject(id) {
    const response = await Vue.axios
      .delete(`/api/image_studio/${id}?projectType=Dermaid`)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });

    return response;
  },

  /**
   * Export Dermaid project.
   * @param projectId
   */
  async exportOvioaiProject(projectId, outputFilename) {
    try {
      const response = await Vue.axios.post(`/api/dermaid/${projectId}/download`, { outputFilename });

      return response.data.url;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error exporting Dermaid project ID ${projectId}:`, error);
      throw error;
    }
  },

};
