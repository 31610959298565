<template>
  <v-container>
    <v-row class="mt-2">
      <h1 class="subHeader">
        {{ $t('tagsPage.title') }}
      </h1>
    </v-row>
    <v-data-table
      :headers="tableHeaders"
      :items="tags"
      :loading="loading"
      :must-sort="true"
      class="account-table mt-4"
    >
      <template
        #item="{item}"
      >
        <tr>
          <td
            v-for="header in tableHeaders"
            :key="header.value + item.id"
            :class="'text-xs-' + header.align"
            @click="gotoCapturePage(item.id)"
          >
            {{ item[header.value] }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import tagsService from '../js/services/tagsService';
import { CAPTURES_PAGE } from '../js/router/pages';

export default {
  name: 'Tags',
  data() {
    return {
      loading: 'secondary',
      total:   0,
      options: {
        page:         1,
        itemsPerPage: 10,
        sortBy:       ['name'],
      },
      tableHeaders: [
        {
          text:  'Name',
          value: 'name',
          align: 'left',
          width: '50%',
        },
        {
          text:  'Capture Count',
          value: 'capture_count',
          width: '50%',
        },
      ],
      tags: [],
    };
  },
  created() {
    this.loadTags();
  },
  methods: {
    async loadTags() {
      this.tags = await tagsService.loadTags();
      this.loading = false;
    },
    gotoCapturePage(id) {
      this.$router.push({ name: CAPTURES_PAGE, params: { tag: id } });
    },
  },
};
</script>
<style lang="scss" scoped>

@import "../css/variables";
@import "../css/components/_container.scss";

.subHeader {
  color: $grey-darken-1;
}

.account-table {
  width: 100%;

  tr:nth-child(2n) td {
    background-color: var(--v-greyAlt1-base);
  }

  tr:hover td {
    background-color: var(--v-greyAlt2-base);
    cursor: pointer;
  }
}
</style>
