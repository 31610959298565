import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  // preset,
  icons: {
    iconfont: 'md', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  theme: {
    dark:   false,
    themes: {
      light: {
        primary:          '#002A4A',
        secondary:        '#E65100',
        accent:           '#E60023',
        warning:          '#E6C300',
        // error:     '#BF360C',
        pageBackground:   colors.grey.lighten5,
        headerBackground: colors.shades.white,
        greyAlt1:         colors.grey.lighten3,
        greyAlt2:         colors.grey.lighten2,
        greyAlt3:         '#E3E3E3',
      },
      dark: {
        primary:        colors.grey.darken2,
        secondary:      '#E65100',
        accent:         '#E60023',
        warning:        '#E6C300',
        // error:     '#BF360C',
        pageBackground: colors.grey.darken4,
        button:         colors.grey.darken1,
        greyAlt1:       colors.grey.darken2,
        greyAlt2:       colors.grey.darken3,
        greyAlt3:       colors.grey.darken1,
      },
    },
    options: {
      customProperties: true,
    },
  },
});
