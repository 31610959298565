<template>
  <v-container class="grey lighten-2">
    <v-row>
      <v-col cols="1" />
      <v-col cols="10"
             class="d-flex justify-end"
      >
        <v-btn
          class="mx-2"
          :disabled="!selectedMachine.length"
          @click="openEditDialog()"
        >
          {{ $t('machinesTab.editBtn') }}
        </v-btn>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-row>
      <v-col cols="1" />
      <v-col cols="10">
        <v-data-table
          v-model="selectedMachine"
          :headers="headers"
          :items="machines"
          :loading="loading"
          item-key="id"
          show-select
          single-select
        />
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-dialog
      v-model="editDialog"
      persistent
      max-width="600px"
    >
      <v-form>
        <v-card>
          <v-card-title class="text-h6 white--text primary">
            <span class="text-h5">
              {{ $t('machinesTab.editMachines.title') }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-row class="mx-4">
              <v-col>
                <v-text-field
                  v-model="currentMachine.machine_name"
                  required
                  disabled
                  :label="$t('machinesTab.editMachines.name')"
                />
              </v-col>
            </v-row>
            <v-row class="mx-4">
              <v-col>
                <v-text-field
                  v-model="currentMachine.description"
                  :label="$t('machinesTab.editMachines.description')"
                />
              </v-col>
            </v-row>
            <v-row class="mx-4">
              <v-col>
                <v-autocomplete v-model="currentMachine.customer_location_id"
                                :items="locations"
                                item-text="name"
                                item-value="id"
                                menu-props="auto"
                                :label="$t('machinesTab.editMachines.customerLocation')"
                                hide-details
                                single-line
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row class="ma-4">
              <v-btn
                class="mx-1"
                color="secondary"
                @click="updateMachine()"
              >
                {{ $t('machinesTab.editMachines.save') }}
              </v-btn>
              <v-btn
                class="mx-1"
                @click="cancel()"
              >
                {{ $t('machinesTab.editMachines.cancel') }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar
      v-model="saveFinished"
      :timeout="snackBarTimeout"
      color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('machinesTab.saveFinished') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="saveError"
      :timeout="snackBarTimeout"
      color="error"
    >
      <div class="font-weight-bold">
        {{ $t('machinesTab.saveError') }}
      </div>
    </v-snackbar>
  </v-container>
</template>
<script>
import machinesService from '../../js/services/machinesService';

export default {
  name: 'Machines',
  data() {
    return {
      machines:        [],
      locations:       [],
      selectedMachine: [],
      editDialog:      false,
      loading:         'secondary',
      snackBarTimeout: 5000,
      saveFinished:    false,
      saveError:       false,
      currentMachine:  {
        id:                   0,
        name:                 '',
        description:          '',
        customer_location_id: 0,
      },
      headers: [
        {
          text:  this.$t('machinesTab.headers.id'),
          align: 'center',
          value: 'id',
        },
        {
          text:  this.$t('machinesTab.headers.name'),
          align: 'center',
          value: 'machine_name',
        },
        {
          text:  this.$t('machinesTab.headers.description'),
          align: 'center',
          value: 'description',
        },
        {
          text:  this.$t('machinesTab.headers.customer_location'),
          align: 'center',
          value: 'customer_location',
        },
      ],
    };
  },
  async mounted() {
    this.loadMachines();
  },
  methods: {
    async loadMachines() {
      this.loading = 'secondary';
      this.machines = await machinesService.getMachines();
      this.locations = await machinesService.getCustomerLocations();
      this.machines.forEach(m => {
        const location = this.locations.find(l => l.id === m.customer_location_id);

        if (location) {
          m.customer_location =  location.name;
        } else {
          m.customer_location = '';
        }
      });

      this.selectedMachine = [];
      this.loading = false;
    },
    openEditDialog() {
      this.currentMachine = {
        id:                   this.selectedMachine[0].id,
        machine_name:         this.selectedMachine[0].machine_name,
        description:          this.selectedMachine[0].description,
        customer_location_id: this.selectedMachine[0].customer_location_id,
      };

      this.editDialog = true;
    },

    async updateMachine() {
      this.editDialog = false;
      try {
        await machinesService.updateMachine(this.currentMachine);
      } catch {
        this.saveError = true;

        return;
      }
      this.saveFinished = true;
      await this.loadMachines();
    },

    cancel() {
      this.editDialog = false;
    },
  },
};
</script>
