<template>
  <v-container class="px-0 mx-3">
    <v-row>
      <v-col>
        <h1
          class="justify-center text-h5"
        >
          {{ $t('accountPage.captures.title') }}
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col v-for="(capture, index) in captures"
             :key="index"
             cols="4"
      >
        <v-card
          :ripple="false"
          @click="gotoCapture(capture.id)"
        >
          <v-menu
            close-on-click
            offset-y
            offset-x
          >
            <template #activator="{on}">
              <v-btn
                color="white"
                class="my-2"
                :class="$style.btn"
                tile
                icon
                small
                absolute
                right
                :ripple="false"
                v-on="on"
              >
                <v-icon large>
                  more_vert
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="isDermaidEnabled() && capture.compression_type !== 2"
                @click="addToDermaidStudio(capture)"
              >
                <v-list-item-title>
                  Add to oVio<span :class="$style['ai-studio-text']">Ai</span>
                  Studio
                </v-list-item-title>
              </v-list-item>
              <v-list-group
                v-if="capture.compression_type !== 2"
                no-action
                @click.stop
              >
                <template #activator>
                  <v-list-item-title>
                    {{ $t('accountPage.captures.presets') }}
                  </v-list-item-title>
                </template>

                <v-list-item
                  v-for="profile in presetProfiles"
                  :key="profile.id"
                  class="pl-6"
                  @click="addPresets(capture, profile)"
                >
                  <v-list-item-title v-text="profile.name" />
                </v-list-item>
              </v-list-group>
              <v-list-item
                v-if="capture.compression_type !== 2"
                @click="addVideo(capture)"
              >
                <v-list-item-title>
                  {{ $t('accountPage.captures.video') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isAdminUser()"
                @click="deleteCapture(capture)"
              >
                <v-list-item-title>
                  {{ $t('accountPage.captures.deleteTitle') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-img v-if="capture.thumbnail_url !== undefined ||
                   capture.thumbnail_url !== ''"
                 class="white--text align-end"
                 :class="$style.roundedImg"
                 :src="getCaptureImage(index)"
                 aspect-ratio="1.5"
          >
            <v-card-title
              v-if="capture.uploaded_files < capture.total_files"
              class="secondary--text"
            >
              {{ $t('accountPage.captures.uploading',
                    [capture.uploaded_files, capture.total_files]) }}
            </v-card-title>
          </v-img>
          <v-icon v-else>
            account_box
          </v-icon>
          <v-card-text class="text--primary">
            <v-row>
              <v-col class="d-flex justify-begin py-0">
                {{ getCaptureCreateTime(index) }}
              </v-col>
              <v-col cols="1"
                     class="d-flex justify-end py-0"
              >
                <v-icon
                  v-if="capture.audio_file"
                  color="secondary"
                >
                  audiotrack
                </v-icon>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <AccountCaptureDelete :dialog="showDelete"
                          :capture="delCapture"
                          @close="closeDelete(false)"
                          @ok="closeDelete(true)"
    />
  </v-container>
</template>
<script>
import config from '../../js/config';
import AccountCaptureDelete from './AccountCaptureDelete.vue';
import { CAPTURE_PAGE, DERMAID_STUDIO_PAGE } from '../../js/router/pages';
import dermaidService from '../../js/services/dermaidService';
import capturesService from '../../js/services/capturesService';
import userService from '../../js/services/userService';
import imageStudioService from '../../js/services/imageStudioService';
import videoStudioService from '../../js/services/videoStudioService';
import breadcrumbsService from '../../js/services/breadcrumbsService';
import presetFrameProfileService from '../../js/services/presetFrameProfilesService';

export default {
  name:       'AccountCaptures',
  components: { AccountCaptureDelete },
  props:      {
    account: {
      type:    Object,
      default: () => {},
    },
  },
  data() {
    return {
      showMenu:       false,
      showDelete:     false,
      delCapture:     null,
      presetProfiles: [],
    };
  },
  computed: {
    /**
     * Returns filtered account captures.
     *
     * @return {Array}
     */
    captures() {
      const { captures } = this.account;

      return captures;
    },
  },
  mounted() {
    this.initialize();
  },
  activated() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadPresetFrameProfiles();
    },

    /**
     * Returns true if the user is admin
     *
     * @return {boolean}
     */
    isAdminUser() {
      return userService.isAdmin();
    },

    isDermaidEnabled() {
      return userService.getDermaidEnabled();
    },

    getCaptureCreateTime(index) {
      const capture = this.captures[index];

      return this.$moment(capture.date_created).format(config.dateTimeFormat);
    },
    gotoCapture(id) {
      breadcrumbsService.saveParams({
        accountId: this.account.id,
      });

      this.$router.push({
        name:   CAPTURE_PAGE,
        params: {
          captureId: id,
        },
      });
    },
    getCaptureImage(index) {
      const capture = this.captures[index];

      if (capture && capture.thumbnail_url) {
        return `${config.apiUrl}${capture.thumbnail_url}`;
      }

      return '';
    },

    async addPresets(capture, presetProfile) {
      const images = await capturesService.getImageList(capture.id);
      const selectedImages = [];
      const presets = presetProfile.preset_frames;

      images
        .filter(el => presets.includes(el.frame + 1))
        .forEach(image => {
          selectedImages.push({
            ...image,
            captureId:   capture.id,
            accountId:   capture.account_id,
            accountName: capture.account_name,
          });
        });
      imageStudioService.addToImageStudio(selectedImages);
    },

    async addVideo(capture) {
      const videoSource = await capturesService.getVideoSrc(capture.id);
      const fullCapture = await capturesService.get(capture.id);

      videoStudioService.addVideo({
        video:        videoSource,
        account_name: fullCapture.account_name,
        scan_mode:    capture.scan_mode,
        date_created: capture.date_created,
        captureId:    capture.id,
        accountId:    capture.account_id,
      });
    },

    async addToDermaidStudio(capture) {
      try {
        const projectData = {
          name:      `${capture.account_name} - ${capture.id}`,
          captureId: capture.id,
        };
        const { id: projectId } = await dermaidService.createDermaidProject(projectData);

        this.$router.push({ name: DERMAID_STUDIO_PAGE, query: { projectId } });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error adding to Dermaid studio:', error);
      }
    },
    deleteCapture(capture) {
      this.showDelete = true;
      this.delCapture = capture;
    },

    closeDelete(refresh) {
      this.showDelete = false;
      if (refresh) {
        this.$emit('update');
      }
    },

    async loadPresetFrameProfiles() {
      try {
        const profiles = await presetFrameProfileService.getPresetFrameProfiles();

        this.presetProfiles = profiles.filter(profile => profile.id !== 9999);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading preset frame profiles:', error);
      }
    },
  },
};
</script>
<style lang="scss" module>

.btn {
  z-index: 1;
}
.roundedImg {
  border-radius: 4px 4px 0 0;
}
.ai-studio-text {
  color: #F26924;
  font-style: italic;
  margin-right: 4px;
}
</style>
