import Vue from 'vue';
import VueMoment from 'vue-moment';
import { sync } from 'vuex-router-sync';
import App from './App.vue';
import router from './js/router/router';
import store from './js/store/store';
import i18n from './js/lang/i18n';
import axios from './js/api/axios';
import authService from './js/services/authService';
import vuetify from './plugins/vuetify';
import context from './js/imageEditor/context';
import './plugins/vee-validate';

sync(store, router);

Vue.config.productionTip = false;
Vue.use(VueMoment);

new Vue({
  i18n,
  router,
  store,
  axios,
  vuetify,
  context,
  render: h => h(App),
}).$mount('#app');

window.__setLogin = async function __setLogin(username, password) {
  await authService.login(username, password);
};
