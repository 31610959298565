<template>
  <v-container
    v-if="projects.length > 0"
    class="px-0 mx-3"
  >
    <v-row>
      <v-col>
        <h1
          class="justify-center text-h5"
        >
          {{ $t('accountPage.dermaidStudio.title') }}
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col v-for="(project, index) in projects"
             :key="index"
             cols="4"
      >
        <v-card
          :ripple="false"
        >
          <v-menu
            v-if="isAdminUser()"
            close-on-click
            offset-y
            offset-x
          >
            <template #activator="{on}">
              <v-btn
                color="white"
                class="my-2 cardMenuBtn"
                tile
                icon
                small
                absolute
                right
                :ripple="false"
                v-on="on"
              >
                <v-icon large
                        :class="{
                          [$style.menuTriggerNoPreview]: !Boolean(project.thumbnail_url) }"
                >
                  more_vert
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="openDeleteDialog(project.id)"
              >
                <v-list-item-title>
                  {{ $t('accountPage.dermaidStudio.deleteProject') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <router-link
            :to="{ name: 'dermaidStudio', query: { projectId: project.id } }"
            :class="$style.cardLink"
          >
            <v-img
              v-if="Boolean(project.thumbnail_url)"
              class="white--text align-end"
              :class="$style.roundedImg"
              :src="project.thumbnail_url"
              aspect-ratio="1.5"
            />
            <div
              v-else
              :class="$style.previewFallback"
            >
              Preview not available
            </div>
            <v-card-text class="text--primary">
              <v-row>
                <v-col class="d-flex justify-begin py-0">
                  {{ getProjectCreateTime(index) }}
                </v-col>
              </v-row>
            </v-card-text>
          </router-link>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="deleteDialog"
      width="unset"
    >
      <v-card>
        <v-card-title class="text-h6 white--text primary">
          {{ $t('accountPage.dermaidStudio.deleteProject') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <div class="text-h6 my-4">
                {{ $t('accountPage.dermaidStudio.deleteDialog.deleteText') }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="secondary"
            @click="deleteDermaidStudioProject()"
          >
            {{ $t('globalActions.delete') }}
          </v-btn>
          <v-btn @click="cancelDelete()">
            {{ $t('globalActions.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="deleteSuccessSnackbar"
      color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('accountPage.dermaidStudio.deleteDialog.success') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="deleteErrorSnackbar"
      color="error"
    >
      <div class="font-weight-bold">
        {{ $t('accountPage.dermaidStudio.deleteDialog.error') }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import config from '../../js/config';
import dermaidService from '../../js/services/dermaidService';
import imageStudioService from '../../js/services/imageStudioService';
import userService from '../../js/services/userService';

const DEFAULT_DATE_PATTERN = '0001-01-01T00:00:00';

export default {
  name:  'AccountDermaidStudio',
  props: {
    account: {
      type:    Object,
      default: () => {},
    },
  },
  data() {
    return {
      projects:              [],
      deleteDialog:          false,
      deleteProjectId:       null,
      deleteSuccessSnackbar: false,
      deleteErrorSnackbar:   false,
    };
  },
  async mounted() {
    await this.loadProjects();
  },
  methods: {
    async loadProjects() {
      this.projects = await imageStudioService.getProjects(this.account.id, 'dermaid');
    },

    getProjectThumbnail(id) {
      return `${config.apiUrl}api/image_studio/${id}/page?pageNumber=1&width=300`;
    },

    getProjectCreateTime(index) {
      const project = this.projects[index];

      if (!project.date_created || project.date_created === DEFAULT_DATE_PATTERN) return '--';

      return this.$moment(project.date_created).format(config.dateTimeFormat);
    },

    /**
     * Returns true if the user is admin.
     */
    isAdminUser() {
      return userService.isAdmin();
    },

    openDeleteDialog(projectId) {
      this.deleteDialog = true;
      this.deleteProjectId = projectId;
    },

    cancelDelete() {
      this.deleteDialog = false;
    },

    async deleteDermaidStudioProject() {
      try {
        await dermaidService.deleteProject(this.deleteProjectId);
        await this.loadProjects();
        this.deleteSuccessSnackbar = true;
      } catch {
        this.deleteErrorSnackbar = true;
      }
      this.deleteDialog = false;
    },
  },
};

</script>

<style lang="scss" module>

  @import '../../css/variables';
  .roundedImg {
    border-radius: 4px 4px 0 0;
  }

  .previewFallback {
    background-color: #f5f5f5;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 128px;
    border-radius: 4px 4px 0 0;
  }

  .cardLink {
    text-decoration: none;
  }

  .menuTriggerNoPreview {
    color: $gray-text !important;
  }
</style>
<style lang="scss" scoped>
  @import '../../css/components/_cardItem';
</style>
