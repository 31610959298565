import Vue from 'vue';

export default {

  async getMachines() {
    return Vue.axios
      .get('api/machines')
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  async updateMachine(data) {
    return Vue.axios
      .put('api/machines', data)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  async getCustomerLocations() {
    return Vue.axios
      .get('api/customer_locations')
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  async addCustomerLocation(data) {
    return Vue.axios
      .post('api/customer_locations', data)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  async updateCustomerLocation(data) {
    return Vue.axios
      .put('api/customer_locations', data)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  async getTimezones() {
    return Vue.axios
      .get('api/admin/timezones')
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

};
