import Vue from 'vue';
import { mapMutations, mapGetters } from 'vuex';
import store from '../store/store';

import {
  ADD_TO_IMAGE_STUDIO,
  ADD_PAGES_TO_IMAGE_STUDIO,
  REMOVE_IMAGES_FROM_IMAGE_STUDIO,
  SET_CAPTURES_VIEW,
  CLEAR_IMAGES_MUTATION,
} from '../store/mutations';
import {
  IMAGE_STUDIO_IMAGES,
  IMAGE_STUDIO_PAGES,
  CAPTURES_VIEW_GETTER,
} from '../store/getters';

export default {
  $store: store,

  /**
   * Mutations from Vuex Store
   */
  ...mapMutations([
    ADD_TO_IMAGE_STUDIO,
    ADD_PAGES_TO_IMAGE_STUDIO,
    REMOVE_IMAGES_FROM_IMAGE_STUDIO,
    SET_CAPTURES_VIEW,
    CLEAR_IMAGES_MUTATION,
  ]),

  /**
   * Getters from Vuex Storage
   */
  ...mapGetters([
    IMAGE_STUDIO_IMAGES,
    IMAGE_STUDIO_PAGES,
    CAPTURES_VIEW_GETTER,
  ]),

  /**
   * mode for the image editor
   */
  modes: {
    freeDrawing: 'freeDrawing',
    rect:        'rect',
    circle:      'circle',
    text:        'text',
    arrow:       'arrow',
    crop:        'crop',
    selectMode:  'selectMode',
  },

  /**
   * Add selected images to image studio.
   */
  addToImageStudio(images) {
    this[ADD_TO_IMAGE_STUDIO](images);
  },

  /**
   * Add selected images to image studio.
   */
  addPagesToImageStudio(pages) {
    this[ADD_PAGES_TO_IMAGE_STUDIO](pages);
  },

  /**
   * Returns images from image studio.
   *
   * @return {Array}
   */
  getImageStudioImages() {
    return this[IMAGE_STUDIO_IMAGES]();
  },

  /**
   * Returns pages of images from image studio.
   *
   * @return {Array}
   */
  getImageStudioPages() {
    return this[IMAGE_STUDIO_PAGES]();
  },

  /**
   * Returns images from image studio.
   *
   * @return {Array}
   */
  getImagesCount() {
    return this[IMAGE_STUDIO_IMAGES]().length;
  },

  /**
   * Remove images from image studio by capture.
   *
   * @param {number} id - Capture identifier.
   */
  removeFromImageStudio(image) {
    this[REMOVE_IMAGES_FROM_IMAGE_STUDIO](image);
  },

  /**
   * Remove all images from image studio.
   */
  removeAllImages() {
    this[CLEAR_IMAGES_MUTATION]();
  },

  /**
   * Get export filename.
   *
   * @params captureIds - list of captureIds to create filename.
   *
   * @return {Promise}
   */
  getDownloadInfo(captureIds, extension) {
    return Vue.axios
      .post(
        `api/image_studio/download_info`,
        {
          captureIds,
          extension,
        },
      )
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  },

  /**
   * Get export images. This will be a zip.
   *
   * @params projectId - Id of the project to download.
   *
   * @return {Promise}
   */
  downloadImageStudioProject(projectId) {
    return Vue.axios
      .get(
        `api/image_studio/${projectId}`, {
          responseType: 'blob',
        },
      )
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  },

  /**
   * Get export image.
   *
   * @params projectId - Id of the project to download.
   *
   * @return {Promise}
   */
  downloadImageStudioPage(projectId, pageNumber) {
    // console.log(`${projectId} ${pageNumber}`);

    return Vue.axios
      .get(
        `api/image_studio/${projectId}/page?pageNumber=${pageNumber}`, {
          responseType: 'blob',
        },
      )
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  },

  /**
   * Finish the image studio upload process.
   *
   * @params projectId - Id of the of project.
   * @params exportType - Jpeg or Pdf
   * @params exportDirection - Direction of the export, for Pdf only
   *
   * @return {Promise}
   */
  async finishExportProject(projectId, exportType, exportDirection) {
    return Vue.axios
      .post(
        `api/image_studio/${projectId}/build?exportType=${exportType}&direction=${exportDirection}`,
      )
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  },

  async createProject() {
    const response = await Vue.axios
      .post(
        'api/image_studio', {
          name: 'Unknown Project Name',
        },
      )
      .catch(error => {
        throw error.response;
      });

    return response.data;
  },

  async getProjectInfo(projectId) {
    const response = await Vue.axios
      .get(`/api/image_studio/${projectId}/info`)
      .catch(error => {
        throw error.response;
      });

    return response.data;
  },

  async getProjects(accountId, projectType) {
    const query = new URLSearchParams({ accountId });

    if (projectType) {
      query.append('projectType', projectType);
    }

    const response = await Vue.axios
      .get(`/api/image_studio?${query.toString()}`)
      .catch(error => {
        throw error.response;
      });

    return response.data;
  },

  async uploadProjectImage(projectId, params) {
    const data = new FormData();

    data.append('PageNumber', params.pageNumber);
    data.append('CaptureId', params.captureId);
    data.append('CameraCaptureSettingsId', params.cameraCaptureSettingsId);
    data.append('Angle', params.angle);
    data.append('Commands', params.commands);
    data.append('ScaleX', params.cropInfo.scaleX);
    data.append('ScaleY', params.cropInfo.scaleY);
    data.append('TranslateX', params.cropInfo.translateX);
    data.append('TranslateY', params.cropInfo.translateY);
    data.append('UploadFile', params.data);

    return Vue.axios
      .post(
        `api/image_studio/${projectId}/image`, data, {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      )
      .catch(error => {
        // console.log(error);
        throw error.response;
      });
  },

  /**
   * Delete Video Studio project.
   * @param {int} id - Video Studio project id.
   */
  async deleteProject(id) {
    const response = await Vue.axios
      .delete(`/api/image_studio/${id}`)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });

    return response.data;
  },
};
