export default {

  /**
   * Parse json object to csv string.
   *
   * @param {Array} header - Array with csv file header column names.
   * @param {Array} data - Array with csv file rows and contains column values.
   *
   * @return {string}
   */
  parseToCsv(header, data) {
    let csv = this.joinRow(header);

    data.forEach(row => {
      csv += this.joinRow(row);
    });

    return csv;
  },

  /**
   * Join array to csv string.
   *
   * @param {Array} row - Array with csv file row data.
   *
   * @returns {string}
   */
  joinRow(row) {
    return `"${row.join('","')}"\n`;
  },

  /**
   * Get csv file name.
   *
   * @param {string} name - Csv file name without extension and date.
   *
   * @return {string}
   */
  getFileName(name) {
    const date = new Date();

    return `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}_${name.replace(' ', '_')}.csv`;
  },
};
