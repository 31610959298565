import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import config from '../config';
import router from '../router/router';
import { LOGIN_PAGE } from '../router/pages';

const http = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

Vue.use(VueAxios, http);

http.interceptors.request.use(
  config => config,
  error => Promise.reject(error),
);

http.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      router.push({ name: LOGIN_PAGE }).catch(() => {});
    }

    return Promise.reject(error);
  },
);

export default http;
