<template>
  <v-container>
    <v-row class="mb-2">
      <v-col>
        <h1 class="subHeader">
          {{ $t('accountsPage.title') }}
        </h1>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn class="mx-1"
               color="secondary"
               fab
               dark
               right
               top
               @click="showAddAccount = true"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table :headers="headers"
                  :items="accounts"
                  :options.sync="options"
                  :server-items-length="total"
                  :loading="loading"
                  :must-sort="true"
                  class="account-table"
    >
      <template #item="{item}">
        <tr>
          <td v-for="header in headers"
              :key="header.value + item.id"
              :class="'text-xs-' + header.align"
              @click="goToAccountPage(item.id)"
          >
            {{ getAccountValue(header, item) }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <AccountData :dialog="showAddAccount"
                 @close="showAddAccount=false"
                 @saved="savedAccount"
                 @error="savedError"
    />
    <v-snackbar v-model="errorSnackbar"
                color="error"
    >
      <div class="font-weight-bold">
        {{ $t('addAccount.error') }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import _ from 'lodash';
import accountFieldsService from '../js/services/accountFieldsService';
import accountsService from '../js/services/accountsService';
import breadcrumbsService from '../js/services/breadcrumbsService';
import AccountData from '../components/Account/AccountData.vue';
import { ACCOUNT_PAGE } from '../js/router/pages';

export default {
  name:       'Accounts',
  components: {
    AccountData,
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.search_term !== from.query.search_term) {
      this.searchText = to.query.search_term;
      this.search();
    }

    next();
  },
  data() {
    return {
      showAddAccount: false,
      loading:        'secondary',
      total:          0,
      searchText:     '',
      selectedFields: [],
      errorSnackbar:  false,
      options:        {
        page:         1,
        itemsPerPage: 10,
        sortBy:       ['firstname'],
        sortDesc:     [false],
      },
      accounts: [],
    };
  },
  computed: {
    /**
     * Returns account fields for advanced search.
     *
     * @return {Array}
     */
    permanentAccountFields() {
      return accountFieldsService.getNotPermanentAccountFields();
    },

    /**
     * Returns all account fields.
     *
     * @return {Array}
     */
    accountFields() {
      return accountFieldsService.getAccountFields();
    },

    /**
     * Get headers for accounts table.
     *
     * @return {Array}
     */
    headers() {
      const fields = accountFieldsService.getAccountFields()
        .filter(h => h.table_position >= 0).sort((a, b) => a.table_position - b.table_position);

      const headers = [];

      headers.push({
        text:  'Id',
        value: 'id',
        type:  '0',
        align: 'left',
      });

      fields.forEach(header => {
        headers.push({
          text:  header.description,
          value: header.key,
          type:  header.type,
          align: 'left',
        });
      });

      return headers;
    },

    /**
     * Returns params for search account.
     *
     * @return {Object}
     */
    searchParams() {
      const params = {
        filter:     this.searchText,
        pageSize:   this.options.itemsPerPage >= 0 ? this.options.itemsPerPage : null,
        pageNumber: this.options.page,
      };

      const fields = accountFieldsService.getPermanentAccountFields().map(field => field.id);

      params.fields = fields.join(',');

      if (this.options.sortBy) {
        const sortBy = this.accountFields.find(field => field.key === this.options.sortBy[0]);

        params.sortColumn = sortBy ? sortBy.id : 0;
      }

      if (this.options.sortDesc) {
        const [sortDescending] = this.options.sortDesc;

        params.sortDescending = sortDescending;
      }

      return params;
    },
  },
  watch: {
    /**
     * Search accounts after changing pagination options.
     */
    options: {
      handler() {
        this.debouncedUpdateAccounts();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$route.query.search_term) {
      this.searchText = this.$route.query.search_term;
    }

    this.debouncedUpdateAccounts = _.debounce(this.updateAccounts, 200);

    this.loadAccountsFields();

    this.updateTotal();

    breadcrumbsService.saveParams({});
  },
  methods: {

    /**
     * Get the account fields from the api
     *
     */
    async loadAccountsFields() {
      await accountFieldsService.loadAccountFields();
    },

    /**
     * Redirect to selected account page.
     *
     * @param {number|Object} id - Account identifier.
     */
    goToAccountPage(id) {
      this.$router.push({
        name:   ACCOUNT_PAGE,
        params: {
          accountId: id,
        },
      });
    },
    /**
     * Search accounts.
     */
    search() {
      this.options.page = 1;
      this.updateTotal();
      this.debouncedUpdateAccounts();
    },

    /**
     * Load total count with filters for accounts table.
     */
    async updateTotal() {
      this.total = await accountsService.loadAccountsCount(this.searchParams);
    },

    /**
     * Load accounts with filters.
     */
    async updateAccounts() {
      this.loading = 'secondary';
      this.accounts = await accountsService.loadAccounts(this.searchParams);
      this.loading = false;
    },

    savedAccount(id) {
      this.goToAccountPage(id);
    },

    savedError() {
      this.errorSnackbar = true;
      this.showAddAccount = false;
    },

    getAccountValue(header, item) {
      if (header.value === 'id') {
        return item.id;
      }

      // console.log(`Header = ${JSON.stringify(header)}`);
      if (header.type === 2) {
        if (item.fields[header.value]) {
          return this.$t(`accountPage.gender[${item.fields[header.value]}]`);
        }
      }

      return item.fields[header.value];
      // return '';
    },

  },
};
</script>
<style lang="scss">
table.v-table {
  word-break: break-all;
}
</style>
<style lang="scss" scoped>

@import "../css/variables";
@import "../css/components/_container.scss";

.subHeader {
  color: $grey-darken-1;
}

.account-table {
  width: 100%;

  tr:nth-child(2n) td {
    background-color: var(--v-greyAlt1-base);
  }

  tr:hover td {
    background-color: var(--v-greyAlt2-base);
    cursor: pointer;
  }
}

.button {
  width: 180px;
}
.v-autocomplete {
  margin-top: 10px;
}
.v-input--checkbox {
  width: 200px;
  float: left;
}
.label-filters {
  font-weight: bold;
  top: 20px;
  position: relative;
}
</style>
