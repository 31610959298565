import { fabric } from 'fabric';

class TextCommand {
  constructor(e) {
    this.canvas = e.data.canvas;
    this.params = e.data.params;
    this.text = e.data.text;
    this.cid = e.data.cid;
    this.commandName = 'Text';
  }

  saveState() {
  }

  undo() {
    this.canvas.remove(this.object);
  }

  execute() {
    // console.log(`${JSON.stringify(this.params)}`);
    this.object = new fabric.Text(this.text, this.params);

    this.canvas.add(this.object);
    this.canvas.bringToFront(this.object);
    this.canvas.renderAll();
  }

  export() {
    const data = {
      params:  this.params,
      text:    this.text,
      command: this.commandName,
      cid:     this.cid,
    };

    return data;
  }
}

export default TextCommand;
