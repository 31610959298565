<template>
  <v-container class="pt-0 px-12">
    <v-row>
      <v-col>
        <h1 class="subHeader">
          {{ $t('exportCaptureDownloadPage.title') }}
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-btn
        class="secondary white--text mr-2"
        @click="downloadFile"
      >
        {{ $t('exportCaptureDownloadPage.download') }}
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import { saveAs } from 'file-saver/FileSaver';
import captureExportsService from '../js/services/captureExportsService';

export default {
  name: 'ExportCaptureDownloadReview',
  data() {
    return {

    };
  },
  methods: {
    async downloadFile() {
      const filename = `captureExport_${this.$route.params.exportCaptureId}.zip`;

      const model = await captureExportsService.download(this.$route.params.exportCaptureId);

      saveAs(new Blob([model], { type: 'application/zip;charset=utf-8' }), filename);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/variables";

.subHeader {
  color: $grey-darken-1;
}

</style>
