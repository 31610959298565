<template>
  <v-container
    v-if="fields.length"
  >
    <v-row>
      <v-col>
        <h1
          class="justify-center text-h5"
        >
          {{ $t('accountPage.documents.title') }}
        </h1>
      </v-col>
    </v-row>
    <v-row v-if="error">
      <v-col>
        <v-alert type="error">
          {{ $t('accountPage.documents.error') }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-for="(field, index) in fields"
           :key="index"
    >
      <v-col>
        <v-file-input
          v-if="accountDocs[field.id]"
          v-model="accountDocs[field.id].file"
          :label="field.description"
          clearable
          color="grey darken-1"
          @change="fileChanged(field.id)"
        >
          <template
            #append
          >
            <v-btn
              v-if="accountDocs[field.id]"
              icon
              width="24"
              height="24"
              @click.native="downloadDocument(field.id)"
            >
              <v-icon>
                cloud_download
              </v-icon>
            </v-btn>
          </template>
        </v-file-input>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { saveAs } from 'file-saver/FileSaver';
import accountDocumentFieldsService from '../../js/services/accountDocumentFieldsService';
import accountsService from '../../js/services/accountsService';

export default {
  name:  'AccountDocuments',
  props: {
    account: {
      type:    Object,
      default: () => {},
    },
  },
  data() {
    return {
      fields:      [],
      accountDocs: {},
      error:       null,
      errorTimer:  null,
    };
  },
  async created() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      await accountDocumentFieldsService.loadAccountDocumentsFields();

      const fields = accountDocumentFieldsService.getAccountDocumentFields();

      fields.forEach(async field => {
        let info = await accountsService.getDocumentInfo(this.account.id, field.id);

        if (info) {
          info.file = new File([''], info.filename);

          this.$set(this.accountDocs, field.id, info);
        } else {
          info = {
            file: null,
          };
          this.$set(this.accountDocs, field.id, info);
        }
      });
      this.fields = fields;
    },
    async fileChanged(fieldId) {
      try {
        if (this.accountDocs[fieldId].file) {
          await accountsService.uploadDocument(this.account.id, fieldId,
            this.accountDocs[fieldId].file);
        } else {
          await accountsService.deleteDocument(this.account.id, fieldId);
        }
      } catch (error) {
        this.error = this.$t('accountPage.documents.error');
        this.errorTimer = setInterval(this.clearError, 3000);
      }
      this.loadData();
    },
    async downloadDocument(fieldId) {
      const document = await accountDocumentFieldsService
        .downloadDocument(this.account.id, fieldId);

      saveAs(new Blob([document],
        { type: this.accountDocs[fieldId].content_type }),
      this.accountDocs[fieldId].filename);
    },
    clearError() {
      this.error = null;
      clearInterval(this.errorTimer);
    },
  },
};
</script>
