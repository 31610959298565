import { render, staticRenderFns } from "./CaptureDetailsView.vue?vue&type=template&id=01e74729&scoped=true"
import script from "./CaptureDetailsView.vue?vue&type=script&lang=js"
export * from "./CaptureDetailsView.vue?vue&type=script&lang=js"
import style0 from "./CaptureDetailsView.vue?vue&type=style&index=0&id=01e74729&prod&lang=scss&module=true"
import style1 from "./CaptureDetailsView.vue?vue&type=style&index=1&id=01e74729&prod&lang=scss&scoped=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "01e74729",
  null
  
)

export default component.exports