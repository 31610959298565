import Vue from 'vue';
import VueCommander from 'vuecommander';
import CropCommand from './cropCommand';
import EllipseCommand from './ellipseCommand';
import RectCommand from './rectCommand';
import TextCommand from './textCommand';
import ArrowCommand from './arrowCommand';
import FreeDrawCommand from './freeDrawCommand';

Vue.use(VueCommander);

export default new VueCommander.Context({
  'imageStudio.crop':     CropCommand,
  'imageStudio.ellipse':  EllipseCommand,
  'imageStudio.rect':     RectCommand,
  'imageStudio.text':     TextCommand,
  'imageStudio.arrow':    ArrowCommand,
  'imageStudio.freeDraw': FreeDrawCommand,
});
