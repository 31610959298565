<template>
  <v-container class="grey lighten-2">
    <v-row>
      <v-col cols="1" />
      <v-col cols="10"
             class="d-flex justify-end"
      >
        <v-btn
          color="secondary"
          class="mx-2"
          @click="openAddDialog(true)"
        >
          {{ $t('locationsTab.addBtn') }}
        </v-btn>
        <v-btn
          class="mx-2"
          :disabled="selectedLocation.length === 0"
          @click="openAddDialog(false)"
        >
          {{ $t('locationsTab.editBtn') }}
        </v-btn>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-row>
      <v-col cols="1" />
      <v-col cols="10">
        <v-data-table
          v-model="selectedLocation"
          :headers="headers"
          :items="locations"
          :loading="loading"
          item-key="id"
          show-select
          single-select
        />
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-dialog
      v-model="addDialog"
      persistent
      max-width="700px"
    >
      <v-form>
        <ValidationObserver ref="obs"
                            v-slot="{ invalid, handleSubmit }"
        >
          <v-card>
            <v-card-title class="text-h6 white--text primary">
              <span class="text-h5">
                {{ addMode ?
                  $t('locationsTab.addLocation.title') :
                  $t('locationsTab.editLocation.title')
                }}
              </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="mx-4">
                  <v-col>
                    <ValidationProvider v-slot="{ errors, valid }"
                                        rules="required"
                    >
                      <v-text-field
                        v-model="newLocation.name"
                        :error-messages="errors"
                        :success="valid"
                        :label="$t('locationsTab.addLocation.name')"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row class="mx-4">
                  <v-col>
                    <v-text-field
                      v-model="newLocation.street"
                      :label="$t('locationsTab.addLocation.street')"
                    />
                  </v-col>
                </v-row>
                <v-row class="mx-4">
                  <v-col>
                    <v-text-field
                      v-model="newLocation.city"
                      :label="$t('locationsTab.addLocation.city')"
                    />
                  </v-col>
                </v-row>
                <v-row class="mx-4">
                  <v-col>
                    <v-text-field
                      v-model="newLocation.state"
                      :label="$t('locationsTab.addLocation.state')"
                    />
                  </v-col>
                </v-row>
                <v-row class="mx-4">
                  <v-col>
                    <v-text-field
                      v-model="newLocation.zipcode"
                      :label="$t('locationsTab.addLocation.zip')"
                    />
                  </v-col>
                </v-row>
                <v-row class="mx-4">
                  <v-col>
                    <v-text-field
                      v-model="newLocation.primary_contact"
                      :label="$t('locationsTab.addLocation.contact')"
                    />
                  </v-col>
                </v-row>
                <v-row class="mx-4">
                  <v-col>
                    <v-text-field
                      v-model="newLocation.contact_phone"
                      :label="$t('locationsTab.addLocation.phone')"
                    />
                  </v-col>
                </v-row>
                <v-row class="mx-4">
                  <v-col>
                    <ValidationProvider v-slot="{ errors, valid }"
                                        rules="required"
                    >
                      <v-autocomplete v-model="newLocation.timezone"
                                      :items="timezones"
                                      item-text="friendly_name"
                                      menu-props="auto"
                                      :label="$t('locationsTab.addLocation.timezone')"
                                      :disabled="newLocation.id > 0"
                                      :error-messages="errors"
                                      :success="valid"
                                      single-line
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-row class="ma-4">
                <v-btn
                  v-if="newLocation.id <= 0"
                  class="mx-1"
                  color="secondary"
                  :disabled="invalid"
                  @click="handleSubmit(addLocation)"
                >
                  {{ $t('locationsTab.addLocation.add') }}
                </v-btn>
                <v-btn
                  v-else
                  class="mx-1"
                  color="secondary"
                  :disabled="invalid"
                  @click="handleSubmit(updateLocation)"
                >
                  {{ $t('locationsTab.addLocation.save') }}
                </v-btn>
                <v-btn
                  class="mx-1"
                  @click="cancel()"
                >
                  {{ $t('locationsTab.addLocation.cancel') }}
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </ValidationObserver>
      </v-form>
    </v-dialog>
    <v-snackbar
      v-model="saveFinished"
      :timeout="snackBarTimeout"
      color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('locationsTab.saveFinished') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="saveError"
      :timeout="snackBarTimeout"
      color="error"
    >
      <div class="font-weight-bold">
        {{ $t('locationsTab.saveError') }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import machinesService from '../../js/services/machinesService';

export default {
  name:       'Locations',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      locations:        [],
      selectedLocation: [],
      timezones:        [],
      loading:          'secondary',
      addDialog:        false,
      snackBarTimeout:  5000,
      saveFinished:     false,
      saveError:        false,
      addMode:          false,
      newLocation:      {
        id:              0,
        name:            '',
        street:          '',
        city:            '',
        state:           '',
        zipcode:         '',
        primary_contact: '',
        contact_phone:   '',
      },
      headers: [
        {
          text:  this.$t('locationsTab.headers.id'),
          align: 'center',
          value: 'id',
        },
        {
          text:  this.$t('locationsTab.headers.name'),
          align: 'center',
          value: 'name',
        },
        {
          text:  this.$t('locationsTab.headers.street'),
          align: 'center',
          value: 'street',
        },
        {
          text:  this.$t('locationsTab.headers.city'),
          align: 'center',
          value: 'city',
        },
        {
          text:  this.$t('locationsTab.headers.state'),
          align: 'center',
          value: 'state',
        },
        {
          text:  this.$t('locationsTab.headers.zip'),
          align: 'center',
          value: 'zipcode',
        },
        {
          text:  this.$t('locationsTab.headers.primaryContact'),
          align: 'center',
          value: 'primary_contact',
        },
        {
          text:  this.$t('locationsTab.headers.contactPhone'),
          align: 'center',
          value: 'contact_phone',
        },
        {
          text:  this.$t('locationsTab.headers.timezone'),
          align: 'center',
          value: 'timezone',
        },
      ],
    };
  },
  async mounted() {
    this.loadLocations();
  },
  methods: {
    async loadLocations() {
      this.loading = 'secondary';
      this.locations = await machinesService.getCustomerLocations();
      this.selectedLocation = [];
      this.loading = false;
    },

    async openAddDialog(isAdd) {
      this.addMode = isAdd;

      if (!isAdd) {
        this.newLocation = JSON.parse(JSON.stringify(this.selectedLocation[0]));
      } else {
        this.newLocation = {
          id:              0,
          name:            '',
          street:          '',
          city:            '',
          state:           '',
          zipcode:         '',
          primary_contact: '',
          contact_phone:   '',
        };
      }

      this.timezones = await machinesService.getTimezones();

      this.addDialog = true;
    },

    async addLocation() {
      this.addDialog = false;
      try {
        await machinesService.addCustomerLocation(this.newLocation);
      } catch {
        this.saveError = true;

        return;
      }
      this.saveFinished = true;
      await this.loadLocations();
    },

    async updateLocation() {
      this.addDialog = false;
      try {
        await machinesService.updateCustomerLocation(this.newLocation);
      } catch {
        this.saveError = true;

        return;
      }
      this.saveFinished = true;
      await this.loadLocations();
    },

    cancel() {
      this.addDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../css/variables';
  @import '../../css/components/_settingsTab';

  .title {
    background-color: $blue-title;
  }
</style>
