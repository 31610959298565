<template>
  <div class="image-editor">
    <div class="image-editor__menu">
      <div class="image-editor__left-menu">
        <v-btn-toggle
          v-model="mode"
          color="secondary"
          group
        >
          <v-tooltip
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                :value="modes.freeDrawing"
                class="py-0"
                v-on="on"
                @click="enableLine"
              >
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('imageStudio.tooltips.freeDraw') }}</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                :value="modes.circle"
                class="py-0"
                v-on="on"
                @click="enableEllipse"
              >
                <v-icon>
                  panorama_fish_eye
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('imageStudio.tooltips.ellipse') }}</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                :value="modes.rect"
                class="py-0"
                v-on="on"
                @click="enableRectangle"
              >
                <v-icon>
                  crop_square
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('imageStudio.tooltips.rectangle') }}</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                :value="modes.text"
                class="py-0"
                v-on="on"
                @click="enableText"
              >
                <v-icon>
                  text_fields
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('imageStudio.tooltips.text') }}</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                :value="modes.arrow"
                class="py-0"
                v-on="on"
                @click="enableArrow"
              >
                <v-icon>
                  call_made
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('imageStudio.tooltips.arrow') }}</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                :value="modes.crop"
                class="py-0"
                v-on="on"
                @click="enableCrop"
              >
                <v-icon>
                  crop
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('imageStudio.tooltips.crop') }}</span>
          </v-tooltip>
        </v-btn-toggle>
      </div>
      <div class="image-editor__right-menu">
        <v-menu class="image-editor__right-menu--swatches"
                offset-y
        >
          <template #activator="{ on }">
            <div class="d-flex align-center">
              <v-btn
                class="btn-top-menu-size"
                :color="color"
                min-height="48"
                min-width="48"
                elevation="0"
                v-on="on"
              />
            </div>
          </template>
          <v-color-picker
            v-model="color"
            value="#FF0000"
            hide-canvas
            hide-inputs
            :swatches="swatches"
            show-swatches
            class="mx-auto"
          />
        </v-menu>
        <v-menu class="image-editor__right-menu--line-weight"
                offset-y
                min-height="150"
                min-width="400"
                max-width="450"
        >
          <template #activator="{ on }">
            <div class="d-flex align-center btn-top-menu-size">
              <v-btn icon
                     tile
                     min-height="48"
                     min-width="48"
                     v-on="on"
              >
                <v-icon>line_weight</v-icon>
              </v-btn>
            </div>
          </template>
          <div class="divBackground">
            <span class="text-h5 px-2 py-4">Width</span>
            <v-slider
              v-model="lineWidth"
              height="70"
              :tick-labels="lineWidthLabels"
              min="0"
              max="2"
              ticks="always"
              class="mx-auto"
              color="secondary"
              background-color="white"
              track-color="secondary"
              hide-details
            />
          </div>
        </v-menu>
        <div class="image-editor__right-menu--undo">
          <v-tooltip
            bottom
          >
            <template #activator="{ on }">
              <v-btn icon
                     tile
                     min-height="48"
                     min-width="48"
                     @click="undo"
                     v-on="on"
              >
                <v-icon>undo</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('imageStudio.tooltips.undo') }}</span>
          </v-tooltip>
        </div>
        <div class="image-editor__right-menu--export">
          <v-btn v-if="enableExport"
                 icon
                 tile
                 min-height="48"
                 min-width="48"
                 @click="exportDialog = true"
          >
            <v-icon>cloud_download</v-icon>
          </v-btn>
        </div>
        <div>
          <slot name="export-button" />
        </div>
      </div>
    </div>
    <div class="image-editor__viewer">
      <div v-for="(page, index) in pages"
           :key="`${page.images[0].captureId}_${page.images[0].frame}`"
      >
        <ImageEditorPage
          v-if="index+1===currentPageIndex"
          :ref="`editorPage${index+1}`"
          :page-info="page"
          :page-index="index"
          :highlight-coordinates="highlightCoordinates"
          :details="details"
          :handle-hover-detail="handleHoverDetail"
          :clear-selected-detail="clearSelectedDetail"
          @remove-page="removePage"
          @page-loaded="pageLoaded"
          @commands-updated="commandsUpdated"
        />
      </div>
    </div>
    <div class="image-editor__pagination-controls">
      <v-pagination
        v-model="currentPageIndex"
        :length="pages.length"
      />
    </div>

    <!-- export dialog -->
    <v-dialog
      v-model="exportDialog"
      persistent
      max-width="290"
    >
      <v-card :loading="exportLoading">
        <v-card-title class="text-h6 white--text primary">
          {{ $t('imageStudio.exportDialog.title') }}
        </v-card-title>
        <v-card-text>
          <input v-model="fileName"
                 style="padding: 10px;
                  border: 1px solid;
                  margin-top: 12px;
                  width: 100%;"
                 type="text"
          >
          <v-alert v-if="exportError"
                   type="error"
          >
            {{ $t('imageStudio.exportDialog.error') }}
          </v-alert>
          <v-btn
            class="ma-2"
            :disabled="exportLoading!==false"
            @click="exportImages()"
          >
            Export
          </v-btn>
          <v-btn
            class="ma-2"
            :disabled="exportLoading!==false"
            @click="exportDialog=false"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { saveAs } from 'file-saver/FileSaver';
import imageStudioService from '../../js/services/imageStudioService';
import ImageEditorPage from './ImageEditorPage.vue';
import dermaidService from '../../js/services/dermaidService';

export default {
  name:       'ImageEditor',
  components: {
    ImageEditorPage,
  },
  props: {
    highlightCoordinates: {
      type:    Object,
      default: () => ({
        x: null,
        y: null,
      }),
    },
    enableExport: {
      type:    Boolean,
      default: false,
    },
    details: {
      type:    Array,
      default: () => [],
    },
    handleHoverDetail: {
      type:    Function,
      default: () => {},
    },
    clearSelectedDetail: {
      type:    Function,
      default: () => {},
    },
  },
  data() {
    return {
      swatches: [
        [ '#FF0000', '#AA0000', '#550000' ],
        [ '#FFFF00', '#AAAA00', '#555500' ],
        [ '#00FF00', '#00AA00', '#005500' ],
        [ '#00FFFF', '#00AAAA', '#005555' ],
        [ '#0000FF', '#0000AA', '#000055' ],
      ],
      isCropping:       false,
      color:            '#FF0000',
      mode:             null,
      modes:            imageStudioService.modes,
      pages:            [],
      currentPageIndex: 1,
      isExporting:      false,
      exportType:       0,
      exportDirection:  0,
      exportDialog:     false,
      exportLoading:    false,
      exportError:      false,
      fileName:         '',
      lineWidth:        1,
      lineWidthLabels:  [
        'Thin',
        'Medium',
        'Thick',
      ],
      lineWidths: [
        {
          line: 4,
          font: 22,
        },
        {
          line: 8,
          font: 32,
        },
        {
          line: 12,
          font: 42,
        },
      ],
    };
  },
  watch: {
    lineWidth() {
      this.setMode();
    },
    color() {
      this.setMode();
    },
    async exportDialog() {
      const images = imageStudioService.getImageStudioImages();

      const response = await imageStudioService.getDownloadInfo(images.map(img => img.captureId));

      this.fileName = response.filename;
    },
    currentPageIndex(newIndex) {
      this.$emit('page-changed', newIndex);
    },
  },
  created() {
    this.loadStorageDataToPages();
  },
  methods: {
    loadStorageDataToPages() {
      this.pages = [];

      const projectType = this.$route.name === 'dermaidStudio' ? 'dermaid' : 'imageStudio';

      if (projectType === 'dermaid') {
        this.pages = dermaidService.getPages();
      } else {
        this.pages = imageStudioService.getImageStudioPages();
      }

      if (this.pages.length > 0) {
        return;
      }

      // Fetch images if no pages were loaded, also based on the project type
      const images = projectType === 'dermaid' ? dermaidService.getDermaidStudioImages() : imageStudioService.getImageStudioImages();

      if (images && images.length > 0) {
        // take the first capture we find and sort the frames
        const capture1 = images.filter(img => images[0].captureId === img.captureId)
          .sort((a, b) => a.frame - b.frame);

        capture1.forEach(cap => {
          const pageGroups = [];

          pageGroups.push(cap);

          // add every other matching frame from the storage to the current group
          images.filter(img => (img.frame === cap.frame) && (img.captureId !== cap.captureId))
            .forEach(match => pageGroups.push(match));

          this.pages.push({
            commands: [],
            images:   pageGroups,
          });
        });

        if (this.$route.name === 'dermaidStudio') {
          dermaidService.addPagesToDermaidStudio(this.pages);
        } else {
          imageStudioService.addPagesToImageStudio(this.pages);
        }
        // console.log(`loadStorageDataToPages ${JSON.stringify(this.pages)}`);
      }
    },

    isValidFileName(filename) {
    // filename should not be empty
      if (!filename.trim()) {
        this.exportError = 'Filename cannot be empty';

        return false;
      }

      // filename should not be too long
      if (filename.length > 255) {
        this.exportError = 'Filename is too long';

        return false;
      }

      return true;
    },

    updatePage(newPage) {
      this.currentPageIndex = newPage;
    },

    removePage(index) {
      // this.pages[this.currentPageIndex - 1].images.splice(index, 1);
      // this.pages = this.pages.filter(p => p.images.length > 0);
      // console.log(`removePage idx = ${index} ${JSON.stringify(this.pages)}`);

      // imageStudioService.addPagesToImageStudio(this.pages);
      const img = this.pages[this.currentPageIndex - 1].images[index];

      this.removeImageFromPage(img.captureId, img.frame);
    },

    setMode() {
      switch (this.mode) {
        case this.modes.freeDrawing:
          this.enableLine();
          break;

        case this.modes.rect:
          this.enableRectangle();
          break;

        case this.modes.circle:
          this.enableEllipse();
          break;

        case this.modes.text:
          this.enableText();
          break;

        case this.modes.arrow:
          this.enableArrow();
          break;

        case this.modes.crop:
          this.enableCrop();
          break;

        default:
          break;
      }
    },

    enableLine() {
      const params = {
        stroke:      this.color,
        strokeWidth: this.lineWidths[this.lineWidth].line,
      };

      this.$refs[`editorPage${this.currentPageIndex}`][0].enableLine(params);
    },
    enableRectangle() {
      const params = {
        stroke:      this.color,
        strokeWidth: this.lineWidths[this.lineWidth].line,
      };

      this.$refs[`editorPage${this.currentPageIndex}`][0].enableRectangle(params);
    },
    enableEllipse() {
      const params = {
        stroke:      this.color,
        strokeWidth: this.lineWidths[this.lineWidth].line,
      };

      this.$refs[`editorPage${this.currentPageIndex}`][0].enableEllipse(params);
    },
    enableText() {
      const params = {
        fill:        this.color,
        fontFamily:  'Arial',
        fontSize:    this.lineWidths[this.lineWidth].font,
        fontWeight:  100,
        fontStyle:   'normal',
        placeholder: 'Add Text',
      };

      this.$refs[`editorPage${this.currentPageIndex}`][0].enableText(params);
    },
    enableArrow() {
      const params = {
        stroke:      this.color,
        strokeWidth: this.lineWidths[this.lineWidth].line,
      };

      this.$refs[`editorPage${this.currentPageIndex}`][0].enableArrow(params);
    },
    enableCrop() {
      this.isCropping = !this.isCropping;
      this.$refs[`editorPage${this.currentPageIndex}`][0].enableCrop();
    },
    undo() {
      this.$refs[`editorPage${this.currentPageIndex}`][0].undo();
    },
    async pageLoaded() {
      if (this.isExporting) {
        // console.log(`Exporting ${this.currentPageIndex}`);
        await this.$refs[`editorPage${this.currentPageIndex}`][0].save(this.currentProjectId, this.currentPageIndex);
        if (this.currentPageIndex + 1 <= this.pages.length) {
          this.currentPageIndex++;
        } else {
          await this.finishedProjectExport();
          this.loadStorageDataToPages();
        }
      } else {
        this.setMode();
      }
    },
    commandsUpdated(index, commands) {
      this.pages[index].commands = commands;

      if (this.$route.name === 'dermaidStudio') {
        dermaidService.addPagesToDermaidStudio(this.pages);
      } else {
        imageStudioService.addPagesToImageStudio(this.pages);
      }
    },
    async finishedProjectExport() {
      try {
        // First tell the server to build the project
        await imageStudioService
          .finishExportProject(this.currentProjectId, this.exportType, this.exportDirection);

        // Now download it.
        // TODO Move this somewhere else
        const data = await imageStudioService
          .downloadImageStudioProject(this.currentProjectId);

        saveAs(new Blob([data], { type: 'application/zip' }), `${this.fileName}`);
      } catch (ex) {
        // console.log(`${ex}`);
        this.exportError = true;
      }

      this.exportLoading = false;
      this.isExporting = false;
      if (this.currentPageIndex === 1) {
        // Refresh the image studio page if there is only one page.
        this.$refs[`editorPage${this.currentPageIndex}`][0].loadContent();
      } else {
        // otherwise we will go back to the first page.
        this.currentPageIndex = 1;
      }
      setTimeout(this.closeExportWindow, 1000);
      this.setMode();
    },

    async exportImages() {
      if (!this.isValidFileName(this.fileName)) {
        return; // Stop execution if the filename is invalid
      }
      this.exportLoading = 'secondary';

      const resp = await imageStudioService.createProject();

      // console.log(`Project Id = ${resp.id}`);
      if (!resp.id) {
        this.exportError = true;
        setTimeout(this.closeExportWindow, 3000);

        return;
      }

      this.currentProjectId = resp.id;
      this.isExporting = true;

      if (this.currentPageIndex !== 1) {
        this.currentPageIndex = 1;
      } else {
        // First save the current page in case there is only one page.
        // Typically the page is saved on destroy, that is never called if there is one.
        // this will trigger the page loaded and start the exporting is the page is 1.
        this.$refs[`editorPage${this.currentPageIndex}`][0].saveCommands();
      }
    },
    closeExportWindow() {
      this.exportDialog = false;
      this.exportError = false;
    },

    addImageToPage(captureId, frame) {
      const images = imageStudioService.getImageStudioImages();

      // take the first capture we find and sort the frames
      const capture = images.find(img => captureId === img.captureId && frame === img.frame);

      this.pages[this.currentPageIndex - 1].images.push(capture);

      // console.log(`addImageToPage ${JSON.stringify(this.pages)}`);
      if (this.$route.name === 'dermaidStudio') {
        dermaidService.addPagesToDermaidStudio(this.pages);
      } else {
        imageStudioService.addPagesToImageStudio(this.pages);
      }
    },

    addImageToDermaidPage(captureId, frame) {
      const images = dermaidService.getDermaidStudioImages();

      // take the first capture we find and sort the frames
      const capture = images.find(img => captureId === img.captureId && frame === img.frame);

      this.pages[this.currentPageIndex - 1].images.push(capture);

      // console.log(`addImageToPage ${JSON.stringify(this.pages)}`);
      dermaidService.addPagesToDermaidStudio(this.pages);
    },

    removeImageFromPage(captureId, frame) {
      let newPages = [];

      this.pages.forEach((p, i) => {
        const idx = p.images.findIndex(c => captureId === c.captureId && frame === c.frame);

        if (idx >= 0) {
          // console.log(`Removing page ${i}idx ${idx}`);
          p.images.splice(idx, 1);
          if (this.currentPageIndex === i + 1) {
            // console.log('Removing 1');
            this.$refs[`editorPage${this.currentPageIndex}`][0].removeCommands(idx);
            p.commands = this.$refs[`editorPage${this.currentPageIndex}`][0].getCommands();
            // console.log(`got cmds ${JSON.stringify(p.commands)}`);
          } else {
            // console.log('Removing 2');
            p.commands = this.removeCommands(p, idx);
          }
        }

        newPages.push(p);
      });

      newPages = newPages.filter(p => p.images.length > 0);

      // console.log(`removeImageFromPage ${JSON.stringify(this.pages)}`);

      if (this.$route.name === 'dermaidStudio') {
        dermaidService.addPagesToDermaidStudio(newPages);
      } else {
        imageStudioService.addPagesToImageStudio(newPages);
      }

      this.loadStorageDataToPages();
    },

    removeCommands(page, imageIndex) {
      const cmds = [];

      while (page.commands.length > 0) {
        const cmd = page.commands.pop();

        if (cmd.cid  !== imageIndex) {
          cmds.push(cmd);
        }

        if (cmd.cid > imageIndex) {
          cmd.cid--;
        }
      }

      return cmds.reverse();
    },
  },

};
</script>

<style lang="scss" scoped>
  @import '../../css/variables';

.image-editor {
  margin: 0;
  padding: 0;

  &__menu {
    display: flex;
    justify-content: space-between;
  }

  &__right-menu {
    display: flex;
  }

  &__viewer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

  .btn-top-menu-size {
    min-height: 48px;
  }

  .title {
    background-color: $blue-title;
  }

  .btnGroup {
    margin: 4px;
    padding: 0 12px;
  }

  .divBackground {
    background-color: #ffffff;
  }
</style>
