import Vue from 'vue';

export default {
  /**
   * Types of the account data field.
   */
  accountFieldTypes: {
    text:   1,
    gender: 2,
    date:   3,
    height: 4,
    phone:  5,
    email:  6,
  },

  /**
   * Get accounts from api.
   *
   * @params {Object} data - Params for search accounts.
   *
   * @return {Array}
   */
  async loadAccounts(params) {
    try {
      const response = await Vue.axios.get('/api/accounts', { params });

      return response.data;
    } catch (err) {
      // console.log(`loadAccounts ${err}`);

      return [];
    }
  },

  /**
   * Get accounts from api.
   *
   * @params {string} search - Text for search account names.
   *
   * @return {Array}
   */
  async loadAccountNames(search) {
    try {
      const response = await Vue.axios.get('/api/accounts/fullnames', {
        params: {
          filter: search,
        },
      });

      return response.data.map(name => ({
        text:  name.name,
        value: name.id,
      }));
    } catch (err) {
      return [];
    }
  },

  /**
   * Get accounts count from api.
   *
   * @params {Object} data - Params for search accounts.
   *
   * @return {number}
   */
  async loadAccountsCount(params) {
    try {
      const response = await Vue.axios.get('/api/accounts/count', { params });

      return response.data.count;
    } catch (err) {
      // console.log(`loadAccountsCount ${err}`);

      return 0;
    }
  },

  /**
   * Update account's data fields.
   *
   * @params {number} id - Account id.
   * @params {Array} fields - Array with account fields values.
   *
   * @return {Object}
   */
  async updateAccount(id, fields) {
    const data = {
      account_values: fields,
    };
    const response = await Vue.axios.put(`/api/accounts/${id}`, data);

    return response.data;
  },

  async getDocumentInfo(accountId, fieldId) {
    try {
      const response = await Vue.axios.get(`/api/accounts/${accountId}/document/${fieldId}/info`);

      return response.data;
    } catch {
      return null;
    }
  },

  async uploadDocument(accountId, fieldId, doc) {
    try {
      const data = new FormData();

      data.append('DocumentFile', doc);

      await Vue.axios.post(`/api/accounts/${accountId}/document/${fieldId}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    } catch (error) {
      throw error.data;
    }
  },

  async deleteDocument(accountId, fieldId) {
    try {
      await Vue.axios.delete(`/api/accounts/${accountId}/document/${fieldId}`);
    } catch (error) {
      throw error.data;
    }
  },

  /**
   * Create account.
   *
   * @params {Array} fields - Array with account fields values.
   *
   * @return {Object}
   */
  async createAccount(fields) {
    const data = {
      account_values: fields,
    };
    const response = await Vue.axios.post('/api/accounts/', data);

    return response.data;
  },

  /**
   * Get account's values.
   *
   * @params {number} id - Account identifier.
   *
   * @return {Object}
   */
  async getAccountInfo(id) {
    const response = await Vue.axios.get(`/api/accounts/${id}`);

    return response.data;
  },

  /**
   * Delete account.
   *
   * @params {number} id - Account identifier.
   *
   * @return {Object}
   */
  async delete(id) {
    let success = true;

    await Vue.axios.delete(`/api/accounts/${id}`)
      .catch(error => {
        if (error.response.status === 400) {
          success = false;
        } else {
          throw error.response;
        }
      });

    return success;
  },
};
