/* eslint-disable */

import { fabric } from 'fabric';

let params;

fabric.LineArrow = fabric.util.createClass(fabric.Line, {

  type: 'lineArrow',
  initialize(element, options) {
    params = options;
    options || (options = {});
    this.callSuper('initialize', element, options);
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'));
  },

  _render(ctx) {
    this.ctx = ctx;
    this.callSuper('_render', ctx);

    const p = this.calcLinePoints();
    let xDiff = this.x2 - this.x1;
    let yDiff = this.y2 - this.y1;
    let angle = Math.atan2(yDiff, xDiff);
    this.drawArrow(angle, p.x2, p.y2);
  },

  drawArrow(angle, xPos, yPos) {
    this.ctx.save();
    this.ctx.translate(xPos, yPos);
    this.ctx.rotate(angle);
    this.ctx.beginPath();
    this.ctx.moveTo(10, 0);

    const width = params.strokeWidth < 2 ? params.strokeWidth * 6 : params.strokeWidth * 2;

    this.ctx.lineTo(-(width - 2), width);
    this.ctx.lineTo(-(width - 2), -width);
    this.ctx.closePath();

    this.ctx.fillStyle = this.stroke;
    this.ctx.fill();
    this.ctx.restore();
  },
});


fabric.LineArrow.fromObject = function (object, callback) {
  callback && callback(new fabric.LineArrow([ object.x1, object.y1, object.x2, object.y2 ], object));
};
fabric.LineArrow.async = true;
fabric.LineArrow.fromObject = function (object, callback) {
  callback && callback(new fabric.LineArrow([ object.x1, object.y1, object.x2, object.y2 ], object));
};
fabric.LineArrow.async = true;

// export default fabric.LineArrow;
/*export default (function () {
  let drag;
  let color;
  let lineWidth;
  let fillArrow;
  let strokeDashArray;
  let properties;

  function Arrow(canvas, draggable = false, params) {
    if (!draggable) {
      drag = false;

      return Arrow;
    }

    if (color && color !== params.stroke) {
      color = params.stroke;
      new Arrow(canvas, draggable, params);

      return Arrow;
    }

    properties = params;
    if (properties) {
      fillArrow = params.fill;
      color = params.stroke;
      lineWidth = params.strokeWidth;
      strokeDashArray = params.strokeDashArray;
    }
    this.canvas = canvas;
    this.className = 'Arrow';
    this.isDrawing = false;
    this.bindEvents();
    drag = draggable;
  }

  Arrow.prototype.bindEvents = function () {
    const inst = this;

    document.onkeydown = e => {
      if (e.which === 46 || e.keycode === 46) {
        inst.canvas.getActiveObjects().forEach(obj => {
          inst.canvas.remove(obj);
        });
      }
      inst.canvas.renderAll();
    };
    inst.selectable = true;

    inst.canvas.off('mouse:down');
    inst.canvas.on('mouse:down', o => {
      inst.onMouseDown(o);
    });
    inst.canvas.on('mouse:move', o => {
      inst.onMouseMove(o);
    });
    inst.canvas.on('mouse:up', o => {
      inst.onMouseUp(o);
    });
    inst.canvas.on('object:moving', () => {
      inst.disable();
    });
  };

  Arrow.prototype.onMouseUp = function () {
    const inst = this;

    if (!inst.isEnable()) {
      return;
    }

    if (drag) {
      this.line.set({
        dirty:         true,
        objectCaching: true,
      });
      if (inst.canvas.getActiveObject()) {
        inst.canvas.getActiveObject().hasControls = false;
        inst.canvas.getActiveObject().hasBorders = false;
        inst.canvas.getActiveObject().lockMovementX = true;
        inst.canvas.getActiveObject().lockMovementY = true;
        inst.canvas.getActiveObject().lockUniScaling = true;
      }
      inst.canvas.renderAll();

      const canvasProperties = { width: inst.canvas.width, height: inst.canvas.height };
      const currentCanvas = { json: inst.canvas.toJSON(), canvas: canvasProperties };

      new CanvasHistory(inst.canvas, currentCanvas);
    }
    inst.disable();
  };

  Arrow.prototype.onMouseMove = function (o) {
    const inst = this;

    inst.canvas.selection = false;
    if (!inst.isEnable()) {
      return;
    }

    const pointer = inst.canvas.getPointer(o.e);
    const activeObj = inst.canvas.getActiveObject();

    activeObj.set({
      x2: pointer.x,
      y2: pointer.y,
    });
    activeObj.setCoords();
    inst.canvas.renderAll();
  };

  Arrow.prototype.onMouseDown = function (o) {
    const inst = this;

    if (!drag) {
      if (inst.canvas.getActiveObject()) {
        inst.canvas.getActiveObject().hasControls = true;
        inst.canvas.getActiveObject().hasBorders = true;
        inst.canvas.getActiveObject().lockMovementX = false;
        inst.canvas.getActiveObject().lockMovementY = false;
        inst.canvas.getActiveObject().lockUniScaling = false;
        inst.canvas.renderAll();
      }
      inst.disable();

      return;
    }
    inst.enable();
    if (inst.canvas.getActiveObject()) {
      inst.canvas.getActiveObject().hasControls = false;
      inst.canvas.getActiveObject().hasBorders = false;
      inst.canvas.getActiveObject().lockMovementX = true;
      inst.canvas.getActiveObject().lockMovementY = true;
      inst.canvas.getActiveObject().lockUniScaling = true;
      inst.canvas.renderAll();
    }

    const pointer = inst.canvas.getPointer(o.e);
    const points = [ pointer.x, pointer.y, pointer.x, pointer.y ];

    this.line = new fabric.LineArrow(points, {
      strokeWidth:        lineWidth,
      strokeDashArray,
      fill:               color,
      stroke:             color,
      originX:            'center',
      originY:            'center',
      hasBorders:         false,
      hasControls:        false,
      objectCaching:      false,
      perPixelTargetFind: true,
      heads:              [ 1, 0 ],
    });
    inst.canvas.add(this.line).setActiveObject(this.line);
  };

  Arrow.prototype.isEnable = function () {
    return this.isDrawing;
  };

  Arrow.prototype.enable = function () {
    this.isDrawing = true;
  };

  Arrow.prototype.disable = function () {
    this.isDrawing = false;
  };

  return Arrow;
}());*/
