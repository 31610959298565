<template>
  <v-container class="pt-0 px-12">
    <v-row>
      <v-col>
        <h1 class="subHeader">
          {{ $t('imageStudioReviewPage.title') }}
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" />
      <v-col>
        <v-btn
          class="mx-2"
          color="secondary"
          @click="exportDialog=true"
        >
          {{ $t('imageStudioReviewPage.download') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col />
      <v-col>
        <v-img
          v-for="index in project.page_count"
          :key="index"
          :max-width="computePageDimensions()"
          :src="getPageUrl(index)"
          class="mb-5"
        >
          <v-hover v-slot="{ hover }">
            <v-expand-transition>
              <div style="height: 100%;">
                <v-btn
                  v-show="hover"
                  icon
                  x-large
                  color="white"
                  @click="downloadImage(index)"
                >
                  <v-icon>
                    cloud_download
                  </v-icon>
                </v-btn>
              </div>
            </v-expand-transition>
          </v-hover>
        </v-img>
      </v-col>
      <v-col />
    </v-row>
    <v-dialog
      v-model="exportDialog"
      persistent
      max-width="290"
    >
      <v-card :loading="exportLoading">
        <v-card-title class="text-h6 white--text primary">
          {{ $t('imageStudio.exportDialog.title') }}
        </v-card-title>
        <v-card-text>
          <input
            v-model="fileName"
            style="padding: 10px;
                  border: 1px solid;
                  margin-top: 12px;
                  width: 100%;"
            type="text"
          >
          <v-alert
            v-if="exportError"
            type="error"
          >
            {{ $t('imageStudio.exportDialog.error') }}
          </v-alert>
          <v-btn
            class="ma-2"
            :disabled="exportLoading!==false"
            @click="downloadProject()"
          >
            Export
          </v-btn>
          <v-btn
            class="ma-2"
            :disabled="exportLoading!==false"
            @click="exportDialog=false"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { saveAs } from 'file-saver/FileSaver';
import { CAPTURE_PAGE } from '../js/router/pages';
import imageStudioService from '../js/services/imageStudioService';
import config from '../js/config';

export default {
  name: 'ImageStudioReview',
  data() {
    return {
      project:       {},
      images:        [],
      exportDialog:  false,
      exportLoading: false,
      exportError:   false,
      fileName:      '',
    };
  },
  watch: {
    async exportDialog() {
      const captureIds = this.project.captures.map(capture => capture.capture_id);

      const response = await imageStudioService.getDownloadInfo(captureIds);

      this.fileName = response.filename;
    },
  },
  async downloadImage(pageNumber) {
    const data = await imageStudioService.downloadImageStudioPage(this.project.id, pageNumber);

    saveAs(new Blob([data], { type: 'image/jpeg' }), `${this.fileName}`);
  },

  async mounted() {
    await this.loadProject();
  },
  methods: {
    async loadProject() {
      try {
        this.project = await imageStudioService.getProjectInfo(
          this.$route.params.imageStudioId,
          false,
        );
      } catch (error) {
        // console.log(error);
        this.exportNotFound = true;
      }
    },
    isValidFileName(filename) {
      // filename should not be empty
      if (!filename.trim()) {
        this.exportError = 'Filename cannot be empty';

        return false;
      }

      // filename should not be too long
      if (filename.length > 255) {
        this.exportError = 'Filename is too long';

        return false;
      }

      return true;
    },

    computePageDimensions() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 1600;

        case 'sm':
          return 1600;

        case 'md':
          return 1600;

        case 'lg':
          return 1600;

        default:
          return 1600;

        case 'xl':
          return 1600;
      }
    },
    getPageUrl(pageNumber) {
      return `${config.apiUrl}api/image_studio/${this.project.id}/page?pageNumber=${pageNumber}&width=${this.computePageDimensions()}`;
    },
    async downloadProject() {
      const data = await imageStudioService
        .downloadImageStudioProject(this.project.id);

      saveAs(new Blob([data], { type: 'application/zip' }), `${this.fileName}`);
    },

    async downloadImage(pageNumber) {
      // console.log(pageNumber);

      const data = await imageStudioService.downloadImageStudioPage(this.project.id, pageNumber);

      saveAs(new Blob([data], { type: 'image/jpeg' }), `${this.project.id}_${pageNumber}.jpg`);
    },

    captureInfo(index) {
      return `${this.project.captures[index].account_name} - ${this.project.captures[index].capture_id}`;
    },

    close() {
      this.exportDialog = false;
      this.exportError = false;
    },

    gotoCapture(index) {
      this.$router.push({
        name:   CAPTURE_PAGE,
        params: {
          captureId: this.project.captures[index].capture_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/variables";

.subHeader {
  color: $grey-darken-1;
}
</style>
