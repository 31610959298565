<template>
  <div class="fullscreen-viewer">
    <div v-if="!isFullscreen && pages.length > 0"
         class="fullscreen-viewer__editor-menu"
    >
      <div class="fullscreen-viewer__editor-menu--left-menu">
        <v-btn-toggle
          v-model="mode"
          color="secondary"
          group
        >
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                :value="modes.freeDrawing"
                class="py-0"
                v-on="on"
                @click="enableLine"
              >
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('imageStudio.tooltips.freeDraw') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                :value="modes.circle"
                class="py-0"
                v-on="on"
                @click="enableEllipse"
              >
                <v-icon>
                  panorama_fish_eye
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('imageStudio.tooltips.ellipse') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                :value="modes.rect"
                class="py-0"
                v-on="on"
                @click="enableRectangle"
              >
                <v-icon>
                  crop_square
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('imageStudio.tooltips.rectangle') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                :value="modes.text"
                class="py-0"
                v-on="on"
                @click="enableText"
              >
                <v-icon>
                  text_fields
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('imageStudio.tooltips.text') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                :value="modes.arrow"
                class="py-0"
                v-on="on"
                @click="enableArrow"
              >
                <v-icon>
                  call_made
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('imageStudio.tooltips.arrow') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                :value="modes.crop"
                class="py-0"
                v-on="on"
                @click="enableCrop"
              >
                <v-icon>
                  crop
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('imageStudio.tooltips.crop') }}</span>
          </v-tooltip>
        </v-btn-toggle>
      </div>
      <div class="fullscreen-viewer__editor-menu--right-menu">
        <v-menu class="image-editor__right-menu--swatches"
                offset-y
        >
          <template #activator="{ on }">
            <div class="d-flex align-center">
              <v-btn
                class="btn-top-menu-size"
                :color="color"
                min-height="48"
                min-width="48"
                elevation="0"
                v-on="on"
              />
            </div>
          </template>
          <v-color-picker
            v-model="color"
            value="#FF0000"
            hide-canvas
            hide-inputs
            :swatches="swatches"
            show-swatches
            class="mx-auto"
          />
        </v-menu>
        <v-menu class="image-editor__right-menu--line-weight"
                offset-y
                min-height="150"
                min-width="400"
                max-width="450"
        >
          <template #activator="{ on }">
            <div class="d-flex align-center btn-top-menu-size">
              <v-btn icon
                     tile
                     min-height="48"
                     min-width="48"
                     v-on="on"
              >
                <v-icon>line_weight</v-icon>
              </v-btn>
            </div>
          </template>
          <div class="divBackground">
            <span class="text-h5 px-2 py-4">Width</span>
            <v-slider
              v-model="lineWidth"
              height="70"
              :tick-labels="lineWidthLabels"
              min="0"
              max="2"
              ticks="always"
              class="mx-auto"
              color="secondary"
              background-color="white"
              track-color="secondary"
              hide-details
            />
          </div>
        </v-menu>
        <div class="image-editor__right-menu--undo">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn icon
                     tile
                     min-height="48"
                     min-width="48"
                     @click="undo"
                     v-on="on"
              >
                <v-icon>undo</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('imageStudio.tooltips.undo') }}</span>
          </v-tooltip>
        </div>
        <div class="image-editor__right-menu--export">
          <v-btn v-if="enableExport"
                 icon
                 tile
                 min-height="48"
                 min-width="48"
                 @click="openExportDialog()"
          >
            <v-icon>cloud_download</v-icon>
          </v-btn>
        </div>
        <div>
          <slot name="export-button" />
        </div>
      </div>
    </div>
    <div class="fullscreen-viewer__pages">
      <div class="fullscreen-viewer__pages-detail-viewer">
        <div v-for="(page, index) in pages"
             :key="index"
             class="editor-page-wrapper"
        >
          <FullscreenImageEditorPage
            v-if="index+1===currentPageIndex"
            :ref="`editorPage${index+1}`"
            :page-info="page"
            :page-index="index"
            :details="details"
            :handle-hover-detail="handleHoverDetail"
            :clear-selected-detail="clearSelectedDetail"
            :show-image-info="!isFullscreen"
            :enable-zoom="isFullscreen"
            :is-dermaid-viewer="true"
            :canvas-height="canvasHeight"
            :canvas-width="canvasWidth"
            @page-loaded="pageLoaded"
            @commands-updated="commandsUpdated"
            @zoom-changed="onZoomChanged"
            @cursor-over-lesion="onCursorOverLesion"
          />
        </div>
      </div>
    </div>

    <div :class="{'fullscreen-viewer__controls-fixed': isFullscreen}"
         class="fullscreen-viewer__controls"
    >
      <div v-if="isFullscreen"
           class="fullscreen-viewer__controls--zoom"
      >
        <v-btn icon
               color="white"
               @click="decreaseZoom"
        >
          <v-icon>remove</v-icon>
        </v-btn>
        <v-slider v-model="zoom"
                  min="0.3"
                  max="2"
                  step="0.01"
                  class="zoom-slider"
                  color="white"
                  @change="onSlideChange"
        />
        <v-btn icon
               color="white"
               @click="increaseZoom"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </div>
      <v-pagination
        v-model="currentPageIndex"
        :length="pages.length"
      />
    </div>
    <!-- export dialog -->
    <v-dialog
      v-model="exportDialog"
      persistent
      max-width="290"
    >
      <v-card :loading="exportLoading">
        <v-card-title class="text-h6 white--text primary">
          {{ $t('imageStudio.exportDialog.title') }}
        </v-card-title>
        <v-card-text>
          <input v-model="fileName"
                 style="padding: 10px;
                  border: 1px solid;
                  margin-top: 12px;
                  width: 100%;"
                 type="text"
          >
          <v-alert v-if="exportError"
                   type="error"
          >
            {{ $t('imageStudio.exportDialog.error') }}
          </v-alert>
          <v-btn
            class="ma-2"
            :disabled="exportLoading!==false"
            @click="exportProjectAi()"
          >
            Export
          </v-btn>
          <v-btn
            class="ma-2"
            :disabled="exportLoading!==false"
            @click="closeExportWindow"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FullscreenImageEditorPage from '@/components/imageStudio/FullscreenImageEditorPage.vue';
import dermaidService from '@/js/services/dermaidService';
import imageStudioService from '@/js/services/imageStudioService';
import { saveAs } from 'file-saver';

export default {
  name:       'FullScreenViewer',
  components: {
    FullscreenImageEditorPage,
  },
  props: {
    highlightSelected: {
      type:    Object,
      default: null,
    },
    isFullscreen: {
      type:    Boolean,
      default: false,
    },
    enableExport: {
      type:    Boolean,
      default: false,
    },
    details: {
      type:    Array,
      default: () => [],
    },
    handleHoverDetail: {
      type:    Function,
      default: () => {
      },
    },
    clearSelectedDetail: {
      type:    Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      zoom:             0,
      currentPageIndex: 1,
      canvasWidth:      600,
      canvasHeight:     600,
      pages:            [],
      swatches:         [
        [ '#FF0000', '#AA0000', '#550000' ],
        [ '#FFFF00', '#AAAA00', '#555500' ],
        [ '#00FF00', '#00AA00', '#005500' ],
        [ '#00FFFF', '#00AAAA', '#005555' ],
        [ '#0000FF', '#0000AA', '#000055' ],
      ],
      isCropping:      false,
      color:           '#FF0000',
      mode:            null,
      modes:           imageStudioService.modes,
      isExporting:     false,
      exportType:      0,
      exportDirection: 0,
      exportDialog:    false,
      exportLoading:   false,
      exportError:     false,
      fileName:        '',
      lineWidth:       1,
      lineWidthLabels: [
        'Thin',
        'Medium',
        'Thick',
      ],
      lineWidths: [
        {
          line: 4,
          font: 22,
        },
        {
          line: 8,
          font: 32,
        },
        {
          line: 12,
          font: 42,
        },
      ],
    };
  },
  watch: {
    isFullscreen(value) {
      if (value) {
        this.canvasHeight = window.screen.height;
        this.canvasWidth = window.screen.height;
      } else {
        this.canvasHeight = 600;
        this.canvasWidth = 600;
      }
    },
    currentPageIndex(newIndex) {
      this.$emit('page-changed', newIndex);
    },
    lineWidth() {
      this.setMode();
    },
    color() {
      this.setMode();
    },
    highlightSelected(detail) {
      this.onHighlightSelected(detail);
    },
  },
  created() {
    this.loadStorageDataToPages();
  },
  methods: {
    onEnterFullscreen() {
      this.$emit('fullscreen-pressed');
    },
    onCursorOverLesion(event) {
      this.$emit('cursor-over-lesion', event);
      this.$refs[`editorPage${this.currentPageIndex}`][0].drawRect(event.x, event.y, 36, 36);
    },
    onSlideChange(value) {
      this.$refs[`editorPage${this.currentPageIndex}`][0].changeZoom(value);
    },
    onZoomChanged(zoom) {
      this.zoom = zoom;
    },
    onHighlightSelected(detail) {
      if (detail) {
        this.$refs[`editorPage${this.currentPageIndex}`][0].drawRect(detail.x, detail.y, 36, 36);
      }
    },
    decreaseZoom() {
      if (this.zoom <= 0) {
        this.zoom = 0;

        return;
      }

      this.zoom -= 0.01;
      this.$refs[`editorPage${this.currentPageIndex}`][0].changeZoom(this.zoom);
    },
    increaseZoom() {
      if (this.zoom >= 2.0) {
        this.zoom = 2.0;

        return;
      }

      this.zoom += 0.01;
      this.$refs[`editorPage${this.currentPageIndex}`][0].changeZoom(this.zoom);
    },
    setMode() {
      switch (this.mode) {
        case this.modes.freeDrawing:
          this.enableLine();
          break;

        case this.modes.rect:
          this.enableRectangle();
          break;

        case this.modes.circle:
          this.enableEllipse();
          break;

        case this.modes.text:
          this.enableText();
          break;

        case this.modes.arrow:
          this.enableArrow();
          break;

        case this.modes.crop:
          this.enableCrop();
          break;

        default:
          break;
      }
    },
    loadStorageDataToPages() {
      this.pages = dermaidService.getPages();

      if (this.pages.length > 0) {
        return;
      }

      // Fetch images if no pages were loaded
      const images = dermaidService.getDermaidStudioImages();

      if (images && images.length > 0) {
        // take the first capture we find and sort the frames
        const capture1 = images.filter(img => images[0].captureId === img.captureId)
          .sort((a, b) => a.frame - b.frame);

        capture1.forEach(cap => {
          const pageGroups = [];

          pageGroups.push(cap);

          // add every other matching frame from the storage to the current group
          images.filter(img => (img.frame === cap.frame) && (img.captureId !== cap.captureId))
            .forEach(match => pageGroups.push(match));

          this.pages.push({
            commands: [],
            images:   pageGroups,
          });
        });

        if (this.$route.name === 'dermaidStudio') {
          dermaidService.addPagesToDermaidStudio(this.pages);
        } else {
          imageStudioService.addPagesToImageStudio(this.pages);
        }
      }
    },
    async pageLoaded() {
      if (this.isExporting) {
        await this.$refs[`editorPage${this.currentPageIndex}`][0].save(this.currentProjectId, this.currentPageIndex);
        if (this.currentPageIndex + 1 <= this.pages.length) {
          this.currentPageIndex++;
        } else {
          await this.finishedProjectExport();
          this.loadStorageDataToPages();
        }
      } else {
        this.setMode();
      }
    },
    commandsUpdated(index, commands) {
      // this.pages[index].commands = commands;
      // eslint-disable-next-line no-console
      console.log('Fullscreen Viewer commands updated: ', index, commands);

      if (this.$route.name === 'dermaidStudio') {
        // TODO: fix the commands. dermaidService.addPagesToDermaidStudio(this.pages);
      } else {
        imageStudioService.addPagesToImageStudio(this.pages);
      }
    },

    enableLine() {
      const params = {
        stroke:      this.color,
        strokeWidth: this.lineWidths[this.lineWidth].line,
      };

      this.$refs[`editorPage${this.currentPageIndex}`][0].enableLine(params);
    },
    enableRectangle() {
      const params = {
        stroke:      this.color,
        strokeWidth: this.lineWidths[this.lineWidth].line,
      };

      this.$refs[`editorPage${this.currentPageIndex}`][0].enableRectangle(params);
    },
    enableEllipse() {
      const params = {
        stroke:      this.color,
        strokeWidth: this.lineWidths[this.lineWidth].line,
      };

      this.$refs[`editorPage${this.currentPageIndex}`][0].enableEllipse(params);
    },
    enableText() {
      const params = {
        fill:        this.color,
        fontFamily:  'Arial',
        fontSize:    this.lineWidths[this.lineWidth].font,
        fontWeight:  100,
        fontStyle:   'normal',
        placeholder: 'Add Text',
      };

      this.$refs[`editorPage${this.currentPageIndex}`][0].enableText(params);
    },
    enableArrow() {
      const params = {
        stroke:      this.color,
        strokeWidth: this.lineWidths[this.lineWidth].line,
      };

      this.$refs[`editorPage${this.currentPageIndex}`][0].enableArrow(params);
    },
    enableCrop() {
      this.isCropping = !this.isCropping;
      this.$refs[`editorPage${this.currentPageIndex}`][0].enableCrop();
    },
    undo() {
      this.$refs[`editorPage${this.currentPageIndex}`][0].undo();
    },
    openExportDialog() {
      this.fileName = `oVioAi_${this.$moment().format('YYYY_MM_DD')}.zip`;
      this.exportError = null;
      this.downloadUrl = null;
      this.exportDialog = true;
    },

    /**
     * Exports a Ai project
     *
     * @returns {Promise<void>} A promise that resolves after the Ai project has been saved.
     */
    async exportProjectAi() {
      const { projectId } = this.$route.query;

      this.exportLoading = true;
      this.exportError = null;

      try {
        const result = await dermaidService.exportOvioaiProject(projectId, this.fileName);

        await this.downloadFile(result);
      } catch (error) {
        this.exportError = true;
      } finally {
        this.exportLoading = false;
      }
    },

    /**
     * Downloads a file.
     *
     * @param url - The url where to download.
     *
     * @returns {Promise<void>} A promise that resolves after a file has been downloaded.
     */
    async downloadFile(url) {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();

      saveAs(blob, this.fileName);
      setTimeout(() => {
        this.exportDialog = false;
      }, 1000);
    },
    closeExportWindow() {
      this.exportDialog = false;
      this.exportError = false;
    },
  },
};
</script>

<style scoped lang="scss">
.fullscreen-viewer {
  position: relative;
  &__editor-menu {
    display: flex;
    justify-content: space-between;

    &--right-menu {
      display: flex;
    }
  }
  &__pages-detail-viewer {
    display: flex;
    justify-content: center;
  }

  &__pages{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    z-index: 0;
  }

  &__controls-fixed {
    position: absolute;
    bottom: 5rem;
    right: 45%;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--zoom {
      display: flex;
    }
  }

  &__preview-lesion {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 360px;
    height: 360px;
    color: #999999;
    border: 1px solid #E0E0E0;
  }
}
</style>
