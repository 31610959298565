class CropCommand {
  constructor(e) {
    this.canvas = e.data.canvas;
    this.img = e.data.img;
    this.context = e.data.context;
    this.cid = e.data.cid;
    this.params = e.data.params;
    this.oldCommands = [];
    this.commandName = 'Crop';
  }

  saveState() {
    this.prevTop = this.canvas.backgroundImage.top;
    this.prevLeft = this.canvas.backgroundImage.left;
    this.prevScaleX = this.canvas.backgroundImage.scaleX;
    this.prevScaleY = this.canvas.backgroundImage.scaleY;

    while (this.context.history.length > 0) {
      const cmd = this.context.history.pop();

      cmd.undo();
      this.oldCommands.push(cmd);
    }
    this.oldCommands.reverse();

    this.oldCommands.forEach(cmd => {
      if (cmd.cid !== this.cid) {
        cmd.execute();
        this.context.history.push(cmd);
      }
    });
  }

  undo() {
    this.canvas.setBackgroundImage(
      this.img, this.canvas.renderAll.bind(this.canvas), {
        originX: 'left',
        originY: 'top',
        left:    this.prevLeft,
        top:     this.prevTop,
        scaleX:  this.prevScaleX,
        scaleY:  this.prevScaleY,
      },
    );

    while (this.context.history.length > 0) {
      const cmd = this.context.history.pop();

      cmd.undo();
    }

    this.oldCommands.forEach(cmd => {
      cmd.execute();
      this.context.history.push(cmd);
    });
    this.canvas.renderAll();
  }

  execute() {
    this.canvas.setBackgroundImage(
      this.img, this.canvas.renderAll.bind(this.canvas), {
        originX: 'left',
        originY: 'top',
        left:    this.params.left,
        top:     this.params.top,
        scaleX:  this.params.width,
        scaleY:  this.params.height,
      },
    );
    this.canvas.renderAll();
  }

  export() {
    const data = {
      params:  this.params,
      command: this.commandName,
      cid:     this.cid,
    };

    return data;
  }
}

export default CropCommand;
