import { mapMutations, mapGetters } from 'vuex';
import store from '../store/store';
import { BREADCRUMBS_PARAMS_MUTATION } from '../store/mutations';
import { BREADCRUMBS_PARAMS_GETTER } from '../store/getters';

export default {
  $store: store,

  /**
   * Mutations from Vuex Store.
   */
  ...mapMutations([BREADCRUMBS_PARAMS_MUTATION]),

  /**
   * Getters from Vuex Storage.
   */
  ...mapGetters([BREADCRUMBS_PARAMS_GETTER]),

  /**
   * Save breadcrumbs params.
   *
   * @param {Object} params - Breadcrumbs params.
   */
  saveParams(params) {
    this[BREADCRUMBS_PARAMS_MUTATION](params);
  },

  /**
   * Get breadcrumbs params.
   */
  getParams() {
    return this[BREADCRUMBS_PARAMS_GETTER]();
  },
};
