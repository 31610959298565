<template>
  <v-container v-if="capture && capture.id"
               class="pt-0 px-12"
  >
    <v-row>
      <v-breadcrumbs class="text-h2!important"
                     large
                     :items="breadcrumbs"
      >
        <template #item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :exact="item.exact"
            :disabled="item.disabled"
          >
            <h1 v-if="item.disabled"
                class="subHeader"
            >
              {{ item.text }}
            </h1>
            <h1 v-else
                class="breadcrumbLink"
            >
              {{ item.text }}
            </h1>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <CaptureDetails
      :capture="capture"
      @refresh="loadInfo()"
      @tags-updated="updateTags($event)"
    />
    <div v-if="captureNotFound">
      <v-row>
        <v-col cols="12">
          <h2 class="mt-5">
            {{ $t('capturePage.notFound') }}
          </h2>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import CaptureDetails from '../components/Capture/CaptureDetails.vue';
import { ACCOUNT_PAGE, CAPTURES_PAGE } from '../js/router/pages';
import capturesService from '../js/services/capturesService';

export default {
  name:       'Capture',
  components: { CaptureDetails },
  data() {
    return {
      captureNotFound: false,
      capture:         {},
      breadcrumbs:     [],
    };
  },
  async mounted() {
    await this.loadInfo();
  },
  methods: {
    loadBreadcrumbs() {
      this.breadcrumbs = [{
        text:     this.$t('capturesPage.title'),
        disabled: false,
        exact:    true,
        to:       {
          name: CAPTURES_PAGE,
        },
      }];

      const accountBreadcrumbExists = this.breadcrumbs.some(breadcrumb => breadcrumb.text
      === (this.capture && this.capture.account_name));

      if (this.capture && this.capture.account_name && !accountBreadcrumbExists) {
        this.breadcrumbs.push({
          text:     this.capture.account_name,
          disabled: false,
          exact:    true,
          to:       {
            name:   ACCOUNT_PAGE,
            params: {
              accountId: this.capture.account_id,
            },
          },

        });
      }
    },

    async loadCapture() {
      try {
        this.capture = await capturesService.get(this.$route.params.captureId);
      } catch (error) {
        this.captureNotFound = true;
      }
    },

    async loadInfo() {
      await this.loadCapture();
      this.loadBreadcrumbs();
    },

    updateTags(tagNames) {
      this.capture.tag_names = tagNames;
    },

  },
};
</script>
<style lang="scss" scoped>
// @import "../css/components/_container.scss";
@import "../css/variables.scss";
.header {
color: $grey-darken-2;
}

.subHeader {
  color: $grey-darken-1;
}

.breadcrumbLink{
  cursor: pointer;
  color: var(--v-primary-base);
}
</style>
