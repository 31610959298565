<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h6 white--text primary">
          {{ $t('accountPage.deleteTitle') }}
        </v-card-title>
        <v-card-text>
          {{ question }}
          <span
            v-if="error"
            class="error--text"
          >
            {{ error }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="error === ''"
            color="secondary white--text v-btn"
            @click="deleteAccount"
          >
            {{ $t('globalActions.delete') }}
          </v-btn>
          <v-btn
            color="gray darken-3"
            @click="close"
          >
            {{ $t('globalActions.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import accountsService from '../../js/services/accountsService';

export default {
  name:  'AccountDelete',
  props: {
    dialog: {
      type:    Boolean,
      default: false,
    },
    account: {
      type:    Object,
      default: () => {},
    },
  },
  data() {
    return {
      error:      '',
      question:   '',
      showDialog: false,
    };
  },
  watch: {
    dialog(val) {
      this.showDialog = val;
    },
  },
  mounted() {
    this.question = this.$t('accountPage.deleteText');
  },
  methods: {
    /**
     * Delete account.
     */
    async deleteAccount() {
      this.error = '';

      try {
        const response = await accountsService.delete(this.account.id);

        if (response === true) {
          this.$emit('ok');
          this.close();

          return;
        }

        this.setDeleteError(this.$t('accountPage.captureAssociatedError'));
      } catch (error) {
        this.setDeleteError(this.$t('accountPage.deleteError'));
      }

      setTimeout(() => {
        this.close();
      }, 3000);
    },

    /**
     * Launch when account not deleted.
     */
    setDeleteError(error) {
      this.question = '';

      this.error =  error;
    },

    /**
     * Close dialog.
     */
    close() {
      this.showDialog = false;
      this.$emit('close');

      // Reset the text after close but delay it for the close animation
      setTimeout(() => {
        this.error = '';
        this.question = this.$t('accountPage.deleteText');
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../css/variables';

  .title {
    background-color: $blue-title;
  }
</style>
