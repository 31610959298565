<template>
  <div class="app-version">
    oVio Technologies, Inc &copy; v{{ version }}
  </div>
</template>

<script>

import { isEmpty } from 'lodash';

export default {
  name: 'AppVersion',
  data() {
    return {
      version: '',
    };
  },
  mounted() {
    const appVersion = process.env.VUE_APP_VERSION;

    if (isEmpty(appVersion)) {
      // eslint-disable-next-line no-console
      console.error('Application version is not defined');
    }

    this.version = appVersion;
  },
};
</script>

<style lang="scss" scoped>
@import "../css/variables";

.app-version {
  color: grey;
  font-size: 0.8em;
  font-weight: 600;
}
</style>
