export const AUTH_USER_GETTER = 'authUserGetter';
export const AUTHENTICATED_GETTER = 'authenticatedGetter';
export const ACCOUNT_FIELDS_GETTER = 'accountFieldsGetter';
export const ACCOUNT_DOCUMENT_FIELDS_GETTER = 'accountDocumentFieldsGetter';
export const IMAGE_STUDIO_IMAGES = 'imageStudioImagesGetter';
export const IMAGE_STUDIO_PAGES = 'imageStudioPagesGetter';
export const DERMAID_STUDIO_IMAGES = 'dermaidStudioImagesGetter';
export const DERMAID_STUDIO_PAGES = 'dermaidStudioPagesGetter';
export const DERMAID_ENABLED_GETTER = 'dermaidEnabledGetter';
export const LOGIN_REDIRECT_ROUTE = 'loginRedirectLoginGetter';
export const CAPTURES_VIEW_GETTER = 'capturesViewGetter';
export const VIDEO_STUDIO_VIEW_GETTER = 'videoStudioViewGetter';
export const VIDEO_STUDIO_VIDEOS_GETTER = 'videoStudioVideosGetter';
export const VIDEO_STUDIO_SIDEBYSIDE_VIDEOS_GETTER = 'videoStudioSideBySideVideosGetter';
export const VIDEO_STUDIO_PLAYLISTS_VIDEOS_GETTER = 'videoStudioPlaylistsVideosGetter';
export const BREADCRUMBS_PARAMS_GETTER = 'breadcrumbsParamsGetter';
export const DERMAID_PRESETS_GETTER = 'dermaidPresetsGetter';

export default {
  /**
   * Returns auth.user.
   *
   * @return {null|Object}
   */
  [AUTH_USER_GETTER]: state => state.auth.user,

  /**
   * Returns true if user is authenticated.
   *
   * @return {boolean}
   */
  [AUTHENTICATED_GETTER]: state => state.auth.authenticated,

  /**
   * Returns array of account fields.
   *
   * @return {Array}
   */
  [ACCOUNT_FIELDS_GETTER]: state => state.accountFields,

  /**
   * Returns array of account document fields.
   *
   * @return {Array}
   */
  [ACCOUNT_DOCUMENT_FIELDS_GETTER]: state => state.accountDocumentFields,

  /**
   * Returns images from image studio.
   *
   * @return {Array}
   */
  [IMAGE_STUDIO_IMAGES]: state => state.imageStudio.images,

  /**
   * Returns pages from image studio.
   *
   * @return {Object}
   */
  [IMAGE_STUDIO_PAGES]: state => state.imageStudio.project.pages,

  /**
   * Returns Dermaid presets.
   *
   *  @return {Array}
    */
  [DERMAID_PRESETS_GETTER]: state => state.dermaidPresets,

  /**
   * Returns pages from dermaid studio.
   */
  [DERMAID_STUDIO_PAGES]: state => state.dermaidStudio.project.pages,

  /**
   * Returns images from dermaid studio.
   *
   * @return {Array}
   */
  [DERMAID_STUDIO_IMAGES]: state => state.dermaidStudio.images,

  /**
   * Returns the enabled status for Dermaid Studio.
   *
   * @return {boolean}
   */
  [DERMAID_ENABLED_GETTER]: state => state.isDermaidEnabled,

  /**
   * Returns videos from video studio.
   *
   * @return {Array}
   */
  [VIDEO_STUDIO_VIDEOS_GETTER]: state => state.videoStudio.videos,

  /**
   * Returns videos from video studio.
   *
   * @return {Array}
   */
  [VIDEO_STUDIO_SIDEBYSIDE_VIDEOS_GETTER]: state => state.videoStudio.sideBySide,

  /**
   * Returns playlists videos from video studio.
   *
   * @return {Array}
   */
  [VIDEO_STUDIO_PLAYLISTS_VIDEOS_GETTER]: state => state.videoStudio.playlists,

  /**
   * Returns route of the page from which user was redirected to login page.
   *
   * @return {string|null}
   */
  [LOGIN_REDIRECT_ROUTE]: state => state.auth.referrerRoute,

  /**
   * Returns selected capture view.
   *
   * @return {string|null}
   */
  [CAPTURES_VIEW_GETTER]: state => state.captureView,

  /**
   * Returns selected video studio view.
   *
   * @return {string|null}
   */
  [VIDEO_STUDIO_VIEW_GETTER]: state => state.videoStudio.view,

  /**
   * Returns breadcrumbs params.
   *
   * @return {Object}
   */
  [BREADCRUMBS_PARAMS_GETTER]: state => state.breadcrumbs,
};
