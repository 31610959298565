<template>
  <v-container fluid
               class="password-reset"
  >
    <v-snackbar
      v-model="showMessage"
      timeout="8000"
      :color="colorMessage"
      top
      multi-line
    >
      {{ message }}
      <template #action>
        <v-btn
          icon
          @click="showMessage = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card class="password-reset__container">
      <v-img
        src="@/assets/logo.png"
        class="image-pwd-reset"
      />

      <v-card-title class="password-reset__container--title">
        {{ $t('pageTitles.passwordReset') }}
      </v-card-title>

      <v-form
        v-model="valid"
        @submit.prevent="onChangePassword"
        @keyup.enter="onChangePassword"
      >
        <v-text-field
          v-model="password"
          :rules="[passwordRules.required]"
          type="password"
          :label="$t('passwordResetPage.enterPassword')"
        />
        <v-text-field
          v-model="passwordConfirm"
          :rules="[passwordRules.required, passwordRules.emailMatch]"
          type="password"
          :label="$t('passwordResetPage.confirmPassword')"
        />
        <v-card-actions class="password-reset__container--actions">
          <v-btn
            :disabled="!valid"
            class="secondary white--text"
            @click="onChangePassword"
          >
            {{ $t('passwordResetPage.submit') }}
          </v-btn>
        </v-card-actions>

        <app-version class="password-reset__container--app-version" />
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import userService from '../js/services/userService';
import authService from '../js/services/authService';
import { ACCOUNTS_PAGE } from '../js/router/pages';
import AppVersion from '../components/AppVersion.vue';

export default {
  name:       'PasswordReset',
  components: { AppVersion },
  data() {
    return {
      message:         '',
      showMessage:     false,
      colorMessage:    'success',
      valid:           false,
      password:        '',
      passwordConfirm: '',
      passwordRules:   {
        required:   value => !!value || this.$t('passwordResetPage.passwordValidate'),
        emailMatch: value => value === this.password || this.$t('passwordResetPage.passwordMatchValidate'),
      },
    };
  },
  methods: {
    /**
     * Shows the error message notification.
     */
    showErrorMessage(msg) {
      this.showMessage = true;
      this.message = msg;
      this.colorMessage = 'error';
    },
    /**
     * Submit reset request.
     */
    async onChangePassword() {
      this.error = '';
      try {
        const resetData = {};

        resetData.email = this.$route.query.email;
        resetData.pswd_reset_guid = this.$route.query.token;
        resetData.new_password = this.password;

        await userService.updateUserPassword(resetData, false);
        await authService.login(this.$route.query.username, this.password);

        this.$router.push({ name: ACCOUNTS_PAGE });
      } catch (res) {
        const errorMsg = res && res.data ? res.data : this.$t('passwordResetPage.error');

        this.showErrorMessage(errorMsg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/variables";

.password-reset {
  display: flex;
  align-items: center;
  height: 100vh;
  max-width: 26em;
  min-width: 18em;

  padding: 0;
  margin: 0 auto;

  &__container {
    padding: 2em;

    &--title {
      justify-content: center;
    }

    &--actions {
      justify-content: center;
    }

    &--app-version {
      display: flex;
      justify-content: center;
      margin-top: 1em;
    }
  }
}
</style>
