<template>
  <div class="video-wrapper clickable"
       @mouseover="showControls"
       @mouseleave="hideControls"
  >
    <slot />
    <v-container
      class="controls-wrapper pa-0"
      :class="{ show: show }"
      @click.stop=""
    >
      <v-row class="ml-5 mr-5">
        <v-col
          class="progress-bar clickable pa-0"
          align-self="center"
          @click="seek($event)"
        >
          <div class="progress-bar-total" />
          <div class="progress-bar-buffered"
               :style="progressBarBufferedStyle"
          />
          <div class="progress-bar-played"
               :style="progressBarPlayedStyle"
          >
            <div class="progress-bar-scrubber-new"
                 @mousedown.stop="onSlide(seek)"
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="ml-5 mr-5">
        <v-col class="pl-0 pr-0">
          <v-btn
            v-if="showAdvancedControls"
            icon
            color="white"
            @click="previous"
          >
            <v-icon>
              skip_previous
            </v-icon>
          </v-btn>
          <v-btn
            icon
            color="white"
            @click="togglePlay"
          >
            <v-icon v-if="!paused"
                    dark
            >
              pause
            </v-icon>
            <v-icon v-else
                    dark
            >
              play_arrow
            </v-icon>
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col class="d-flex justify-center">
          <div cols="1"
               class="time control-element-wrapper"
          >
            {{ currentTime | timestamp }} / {{ duration | timestamp }}
          </div>
        </v-col>
        <v-spacer />
        <v-col class="d-flex justify-end">
          <v-btn
            icon
            color="white"
            @click="toggleFullscreen"
          >
            <v-icon dark>
              fullscreen
            </v-icon>
          </v-btn>
          <v-btn
            v-if="showAdvancedControls"
            icon
            color="white"
            @click="next"
          >
            <v-icon dark>
              skip_next
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  name:    'VideoCanvasControls',
  filters: {
    timestamp(time) {
      const durationInMinutes = time / 60;
      let seconds = Math.floor((time % 60)) || 0;

      seconds = seconds < 10 ? `0${seconds}` : seconds;

      const minutes = Math.floor(durationInMinutes) || 0;

      return `${minutes}:${seconds}`;
    },
  },
  props: {
    currentTime: {
      type:    Number,
      default: () => 0,
    },
    duration: {
      type:    Number,
      default: () => 0,
    },
    showAdvancedControls: {
      type:    Boolean,
      default: false,
    },
  },
  data() {
    return {
      show:               true,
      playedPercentage:   0,
      bufferedPercentage: 0,
      runningTime:        0,
      totalTime:          0,
      paused:             true,
    };
  },
  computed: {
    progressBarBufferedStyle() {
      return {
        width: `${this.bufferedPercentage}%`,
      };
    },

    progressBarPlayedStyle() {
      return {
        width: `${this.playedPercentage}%`,
      };
    },

  },
  mounted() {
    this.childCanvas = this.$slots.default[0].elm;
    // Seems a little blunt but timeupdate doesn't fire that
    // often so this seems to produce a smoother slider.
    setInterval(() => {
      this.updatePlayedPercentage();
      this.updateBufferedPercentage();
    }, 100);
  },
  methods: {
    showControls() {
      this.show = true;
    },

    hideControls() {
      if (this.paused) {
        return;
      }

      this.show = false;
    },

    togglePlay() {
      if (this.paused) {
        this.$emit('played');
      } else {
        this.$emit('paused');
      }
      this.paused = !this.paused;
    },

    toggleFullscreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else if (document.webkitFullscreenElement) {
        document.webkitExitFullscreen();
      } else if (this.$el.requestFullscreen) {
        this.$el.requestFullscreen();
      } else if (this.$el.webkitRequestFullscreen) {
        this.$el.webkitRequestFullscreen();
      }
    },

    updatePlayedPercentage() {
      const progress = this.currentTime / this.duration;

      this.playedPercentage =  progress * 100;
    },

    updateBufferedPercentage() {
      // const progress = vid.buffered.end(0) / vid.duration;

      // this.bufferedPercentage = progress * 100;
    },

    onSlide(callback) {
      document.addEventListener('mousemove', callback);
      document.addEventListener('mouseup', () => {
        document.removeEventListener('mousemove', callback);
      });
    },
    seek(event) {
      const playFromPercent = this.getMousePositionFromLeftInPercentage('.progress-bar-total', event.clientX);

      const newTime = this.duration * playFromPercent;

      this.$emit('seeked', newTime);
    },

    getMousePositionFromLeftInPercentage(selector, clientX) {
      const element = this.$el.querySelector(selector);
      const mouseFromElementLeft = this.getMousePositionFromLeftInPixels(element, clientX);
      const positionFromLeftInPercentage = mouseFromElementLeft / element.offsetWidth;

      return positionFromLeftInPercentage;
    },

    getMousePositionFromLeftInPixels(element, clientX) {
      const elementLeft = element.getBoundingClientRect().left;
      const mouseFromElementLeft = clientX - elementLeft;

      return mouseFromElementLeft;
    },
    previous() {
      this.$emit('previous');
    },
    next() {
      this.$emit('next');
    },
  },
};
</script>
<style scoped lang="scss">

.clickable {
  cursor: pointer;
}

.video-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.controls-wrapper {
  height: 65px;
  position: relative;
  transition: top 0.2s ease-out;
  top: 10px;
}

.controls-wrapper.show {
  top: -65px;
}

/* PROGRESS BAR */

.progress-bar {
  z-index: 2;
  transition: top 0.2s ease-out;
}

.progress-bar-buffered,
.progress-bar-played,
.progress-bar-total {
  height: 3px;
  width: 0%;
  position: relative;
  transition: width 0.1s
}

.progress-bar-total {
  width: 100%;
  background-color: white;
  opacity: 0.75;
}

.progress-bar-buffered {
  background-color: grey;
  opacity: 0.75;
  top: -3px;
}

.progress-bar-played {
  min-width: 6px;
  background-color: var(--v-secondary-base);
  top: -6px;
}

.progress-bar-scrubber-new {
  height: 3px;
  width: 3px;
  position: absolute;
  right: 0px;
  background-color: var(--v-secondary-base);
  z-index: 2;
  box-sizing: content-box;
  background-clip: content-box;
  border-radius: 50%;
  transition: height 0.2s ease-out, width 0.2s ease-out, top 0.2s ease-out, right 0.2s ease-out;
  top: 0px; /* This appears to be required in FF but not Chrome */
}

.progress-bar:hover .progress-bar-scrubber-new{
  height: 12px;
  width: 12px;
  top: -5px; /* This appears to be required in FF but not Chrome */
  right: -6px;
}

/* END PROGRESS BAR */

/* PLAY/PAUSE BUTTON */

.play {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid white;
}

.pause::before, .pause::after {
  content: '';
  height: 20px;
  width: 5px;
  display: inline-block;
  background-color: white;
}

.pause::after {
  margin-left: 5px;
}

/* END PLAY/PAUSE BUTTON */

/* FULL SCREEN BUTTON */

.fullscreen-wrapper {
  right: 5;
}

.fullscreen {
  width: 20px;
  height: 20px;
  position: relative;
}

.corner {
  position: absolute;
  width: 8px;
  height: 8px;
}

.corner:nth-child(1) {
  left: 0;
  top: 0;
  border-top: 3px solid white;
  border-left: 3px solid white
}

.corner:nth-child(2) {
  right: 0;
  top: 0;
  border-top: 3px solid white;
  border-right: 3px solid white
}

.corner:nth-child(3) {
  bottom: 0;
  right: 0;
  border-bottom: 3px solid white;
  border-right: 3px solid white
}

.corner:nth-child(4) {
  left: 0;
  bottom: 0;
  border-bottom: 3px solid white;
  border-left: 3px solid white
}

/* END FULL SCREEN BUTTON */

/* TIMESTAMP */

.time {
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  text-align: center;
  margin: auto;
}

/* END TIMESTAMP*/
</style>
