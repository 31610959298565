import Vue from 'vue';
import { mapMutations, mapGetters } from 'vuex';
import store from '../store/store';
import config from '../config';
import {
  SET_CAPTURES_VIEW,
  CLEAR_IMAGES_MUTATION,
} from '../store/mutations';
import {
  IMAGE_STUDIO_IMAGES,
  CAPTURES_VIEW_GETTER,
} from '../store/getters';
import applicationService from './applicationService';

export default {
  $store: store,

  /**
   * Mutations from Vuex Store
   */
  ...mapMutations([
    SET_CAPTURES_VIEW,
    CLEAR_IMAGES_MUTATION,
  ]),

  /**
   * Getters from Vuex Storage
   */
  ...mapGetters([
    IMAGE_STUDIO_IMAGES,
    CAPTURES_VIEW_GETTER,
  ]),

  /**
   * Views variants for capture slider.
   */
  views: {
    slides:   'slides',
    tiles2:   'tiles2',
    tiles3:   'tiles3',
    video:    'video',
    audio:    'audio',
    viewer3d: 'viewer3d',
    avatar:   'avatar',
  },

  renderModes: {
    color:     'color',
    grey:      'grey',
    wireframe: 'wireframe',
  },

  /**
   * Set selected view for capture.
   *
   * @param view - Captures view.
   */
  setView(view) {
    this[SET_CAPTURES_VIEW](view);
  },

  /**
   * Returns selected view for capture.
   *
   * @return {null|string}
   */
  getView() {
    return this[CAPTURES_VIEW_GETTER]() || this.views.slides;
  },

  /**
   * Delete capture.
   *
   * @params {number} id - Capture identifier.
   *
   * @return {Object}
   */
  async delete(id) {
    await Vue.axios.delete(`/api/captures/${id}`);
  },

  /**
   * Get capture details.
   *
   * @params {number} id - Capture identifier.
   *
   * @return {Object}
   */
  async get(id) {
    const response = await Vue.axios.get(`/api/captures/${id}`);

    return response.data;
  },

  /**
   * Get all capture paginated
   *
   * @params {number} id - Capture identifier.
   *
   * @return {Object}
   */
  async getAll(paginationOptions, filters) {
    let url = `/api/captures?pageNumber=${paginationOptions.currentPage}&pageSize=${paginationOptions.capturesPerPage}`;

    if (filters.tags && filters.tags !== '') {
      url += `&tags=${filters.tags}`;
    }

    if (filters.machines && filters.machines !== '') {
      url += `&machines=${filters.machines}`;
    }

    if (filters.locations && filters.locations !== '') {
      url += `&locations=${filters.locations}`;
    }

    if (filters.users && filters.users !== '') {
      url += `&users=${filters.users}`;
    }

    if (filters.startDate && filters.startDate !== '') {
      url += `&startDate=${filters.startDate}`;
    }

    if (filters.endDate && filters.endDate !== '') {
      url += `&endDate=${filters.endDate}`;
    }

    const response = await Vue.axios.get(url);

    return response.data;
  },

  /**
   * Get total capture count
   *
   * @params {number} id - Capture identifier.
   *
   * @return {Object}
   */
  async getCaptureCount(filters) {
    let url = '/api/captures/count';

    let queryParams = '';

    if (filters.tags && filters.tags !== '') {
      queryParams += `tags=${filters.tags}&`;
    }

    if (filters.machines && filters.machines !== '') {
      queryParams += `machines=${filters.machines}&`;
    }

    if (filters.locations && filters.locations !== '') {
      queryParams += `locations=${filters.locations}&`;
    }

    if (filters.users && filters.users !== '') {
      queryParams += `users=${filters.users}&`;
    }

    if (filters.startDate && filters.startDate !== '') {
      queryParams += `startDate=${filters.startDate}&`;
    }

    if (filters.endDate && filters.endDate !== '') {
      queryParams += `endDate=${filters.endDate}&`;
    }

    if (queryParams !== '') {
      url += '?';
      url += queryParams.substring(0, queryParams.length - 1);
    }

    const response = await Vue.axios.get(url);

    return response.data.count;
  },

  /**
   * Get capture's images.
   *
   * @params {number} id - Capture identifier.
   *
   * @return {Object}
   */
  async getImageList(id) {
    const response = await Vue.axios.get(`/api/captures/${id}/image_list`);
    const images = response.data.map(image => ({
      image:                   image.image,
      frame:                   image.angle,
      angle:                   image.angle,
      overhead:                image.overhead,
      captureId:               image.capture_id,
      cameraCaptureSettingsId: image.camera_capture_settings_id,
    }));

    return images;
  },

  /**
   * Get capture video url.
   *
   * @param {number} id - Capture identifier.
   */
  async getVideoSrc(id) {
    try {
      if (applicationService.isInCloudMode()) {
        const url = `/api/captures/${id}/video`;
        const response = await Vue.axios.get(url);

        // We get a Url for cloud scenario
        if (response.data.url) {
          return response.data.url;
        }
      }

      // Handle the local scenario here.
      return `${config.apiUrl}api/captures/${id}/video`;
    } catch (e) {
      return '';
    }
  },

  /**
   * Download the video
   *
   * @param {integer} id - Capture Id
   *
   * @return {Object}
   */
  async downloadVideo(id) {
    if (applicationService.isInCloudMode()) {
      const response = await Vue.axios.get(`/api/captures/${id}/video`);

      // return response.data;
      // We get a Url for cloud scenario
      if (response.data.url) {
        const r = await Vue.axios.get(response.data.url, {
          responseType: 'blob',
        });

        return r.data;
      }
    }

    // should only get here for local install
    const resp = await Vue.axios.get(`/api/captures/${id}/video`, {
      responseType: 'blob',
    });

    return resp.data;
  },

  /**
   * Get capture audio url.
   *
   * @param {number} id - Capture identifier.
   */
  async getAudioSrc(id) {
    try {
      if (applicationService.isInCloudMode()) {
        const url = `/api/captures/${id}/audio`;
        const response = await Vue.axios.get(url);

        // We get a Url for cloud scenario
        if (response.data.url) {
          return response.data.url;
        }
      }

      return `${config.apiUrl}api/captures/${id}/audio`;
    } catch (e) {
      return '';
    }
  },

  /**
   * Get capture 3d object.
   *
   * @param {number} id - Capture identifier.
   */
  async get3dSrc(id) {
    try {
      if (applicationService.isInCloudMode()) {
        const url = `/api/captures/${id}/3d`;
        const response = await Vue.axios.get(url);

        // We get a Url for cloud scenario
        if (response.data.url) {
          return response.data.url;
        }
      }

      return `${config.apiUrl}api/captures/${id}/3d`;
    } catch (e) {
      return '';
    }
  },

  /**
   * Get capture 3d object.
   *
   * @param {number} id - Capture identifier.
   */
  async getUploadStatus(id) {
    try {
      if (applicationService.isInCloudMode()) {
        const url = `/api/captures/${id}/upload_status`;
        const response = await Vue.axios.get(url);

        return response.data;
      }

      return null;
    } catch (e) {
      return '';
    }
  },

  /**
   * Get capture export media
   *
   * @param {number} id - Capture identifier.
   */
  getExportMediaSrc(id) {
    return `/api/captures/${id}/export`;
  },

  /**
   * Get capture preview image.
   *
   * @param {number} id - Capture identifier.
   *
   * @return {string}
   */
  getPreview(id) {
    return `${config.apiUrl}api/captures/${id}/images?angle=89&width=300&height=300`;
  },

  /**
   * Download capture media (zip) as blob by url.
   *
   * @params {string} url - Export url.
   *
   * @return {Blob}
   */
  async exportMedia(url) {
    const response = await Vue.axios.get(url, {
      responseType: 'blob',
    });

    return response.data;
  },

  async update(capture) {
    try {
      const response = await Vue.axios.put(`/api/captures/${capture.id}`, capture);

      return response.data;
    } catch {
      return null;
    }
  },

  getFrameRate(scanMode) {
    switch (scanMode) {
      case 0:
        return 30;

      case 1:
        return 30;

      case 2:
        return 30;

      case 3:
        return 30;

      case 4:
        return 24;

      case 5:
        return 24;

      case 6:
        return 20;

      case 7:
        return 20;

      case 8:
        return 15;

      case 9:
        return 15;

      default:
        return 30;
    }
  },
};
