<template>
  <v-container
    v-if="projects.length > 0"
    class="px-0 mx-3"
  >
    <v-row>
      <v-col>
        <h1
          class="justify-center text-h5"
        >
          {{ $t('accountPage.imageStudio.title') }}
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col v-for="(project, index) in projects"
             :key="index"
             cols="4"
      >
        <v-card
          :ripple="false"
          @click="gotoImageStudioProject(project.id)"
        >
          <v-menu
            v-if="isAdminUser()"
            close-on-click
            offset-y
            offset-x
          >
            <template #activator="{on}">
              <v-btn
                color="white"
                class="my-2 cardMenuBtn"
                tile
                icon
                small
                absolute
                right
                :ripple="false"
                v-on="on"
              >
                <v-icon large>
                  more_vert
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="openDeleteDialog(project.id)"
              >
                <v-list-item-title>
                  {{ $t('accountPage.imageStudio.deleteProject') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-img
            class="white--text align-end"
            :class="$style.roundedImg"
            :src="getProjectThumbnail(project.id)"
            aspect-ratio="1.5"
          />
          <v-card-text class="text--primary">
            <v-row>
              <v-col class="d-flex justify-begin py-0">
                {{ getProjectCreateTime(index) }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="deleteDialog"
      width="unset"
    >
      <v-card>
        <v-card-title class="text-h6 white--text primary">
          {{ $t('accountPage.imageStudio.deleteProject') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <div class="text-h6 my-4">
                {{ $t('accountPage.imageStudio.deleteDialog.deleteText') }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="secondary"
            @click="deleteImageStudioProject()"
          >
            {{ $t('globalActions.delete') }}
          </v-btn>
          <v-btn @click="cancelDelete()">
            {{ $t('globalActions.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="deleteSuccessSnackbar"
      color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('accountPage.imageStudio.deleteDialog.success') }}
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="deleteErrorSnackbar"
      color="error"
    >
      <div class="font-weight-bold">
        {{ $t('accountPage.imageStudio.deleteDialog.error') }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import config from '../../js/config';
import imageStudioService from '../../js/services/imageStudioService';
import userService from '../../js/services/userService';
import { IMAGE_STUDIO_REVIEW_PAGE } from '../../js/router/pages';

export default {
  name:  'AccountImageStudio',
  props: {
    account: {
      type:    Object,
      default: () => {},
    },
  },
  data() {
    return {
      projects:              [],
      deleteDialog:          false,
      deleteProjectId:       null,
      deleteSuccessSnackbar: false,
      deleteErrorSnackbar:   false,
    };
  },
  async mounted() {
    await this.loadProjects();
  },
  methods: {
    async loadProjects() {
      this.projects = await imageStudioService.getProjects(this.account.id);
    },

    gotoImageStudioProject(id) {
      this.$router.push({
        name:   IMAGE_STUDIO_REVIEW_PAGE,
        params: {
          imageStudioId: id,
        },
      });
    },

    getProjectThumbnail(id) {
      return `${config.apiUrl}api/image_studio/${id}/page?pageNumber=1&width=300`;
    },

    getProjectCreateTime(index) {
      const project = this.projects[index];

      return this.$moment(project.date_created).format(config.dateTimeFormat);
    },

    /**
     * Returns true if the user is admin.
     */
    isAdminUser() {
      return userService.isAdmin();
    },

    openDeleteDialog(projectId) {
      this.deleteDialog = true;
      this.deleteProjectId = projectId;
    },

    cancelDelete() {
      this.deleteDialog = false;
    },

    async deleteImageStudioProject() {
      try {
        await imageStudioService.deleteProject(this.deleteProjectId);
        await this.loadProjects();
        this.deleteSuccessSnackbar = true;
      } catch {
        this.deleteErrorSnackbar = true;
      }
      this.deleteDialog = false;
    },
  },
};

</script>

<style lang="scss" module>
  .roundedImg {
    border-radius: 4px 4px 0 0;
  }
</style>
<style lang="scss" scoped>
  @import '../../css/components/_cardItem';
</style>
