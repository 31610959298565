import Vue from 'vue';
import { mapMutations, mapGetters } from 'vuex';
import store from '../store/store';
import { ACCOUNT_DOCUMENT_FIELDS_MUTATION } from '../store/mutations';
import { ACCOUNT_DOCUMENT_FIELDS_GETTER } from '../store/getters';

export default {
  $store: store,

  /**
   * Mutations from Vuex Store
   */
  ...mapMutations([ACCOUNT_DOCUMENT_FIELDS_MUTATION]),

  /**
   * Getters from Vuex Storage
   */
  ...mapGetters([ACCOUNT_DOCUMENT_FIELDS_GETTER]),

  async loadAccountDocumentsFields() {
    try {
      const response = await Vue.axios.get('/api/account_document_fields');

      this[ACCOUNT_DOCUMENT_FIELDS_MUTATION](response.data);
    } catch (err) {
      this[ACCOUNT_DOCUMENT_FIELDS_MUTATION]([]);
    }
  },

  /**
   * Get all account document fields.
   *
   * @return {Array}
   */
  getAccountDocumentFields() {
    return this[ACCOUNT_DOCUMENT_FIELDS_GETTER]().filter(field => field.visible);
  },

  /**
   * Get all account document fields.
   *
   * @return {Array}
   */
  getAllAccountDocumentFields() {
    return this[ACCOUNT_DOCUMENT_FIELDS_GETTER]();
  },

  /**
   * Create new account document field.
   *
   * @param {string} data.key - Account data field key.
   * @param {string} data.description - Account data field description.
   * @param {integer} data.visible - Visible or not visible account data field.
   *
   * @return {Object}
   */
  async addAccountDocumentField(data) {
    try {
      const response = await Vue.axios.post('/api/account_document_fields', data);

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  /**
   * Create new account document field.
   *
   * @param {integer} accountId - Account Id
   * @param {integer} fieldId - Document Field Id
   *
   * @return {Object}
   */
  async downloadDocument(accountId, fieldId) {
    const response = await Vue.axios.get(`/api/accounts/${accountId}/document/${fieldId}`, {
      responseType: 'blob',
    });

    return response.data;
  },

  /**
   * Update account document field.
   *
   * @param {string} data.key - Account data field key.
   * @param {string} data.description - Account data field description.
   * @param {integer} data.visible - Visible or not visible account data field.
   *
   * @returns {Object}
   */
  async updateAccountDocumentField(data) {
    try {
      const response = await Vue.axios.put('/api/account_document_fields', data);

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  /**
   * Delete account document field.
   *
   * @param {integer} accountFieldID - Account document field identificator.
   *
   * @returns {Promise}
   */
  async deleteAccountDocumentField(fieldId) {
    try {
      const response = await Vue.axios.delete(`/api/account_document_fields/${fieldId}`);

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
};
