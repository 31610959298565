<template>
  <v-card>
    <v-toolbar
      :class="$style.title"
      dense
      class="justify-center primary white--text"
    >
      <v-toolbar-title>{{ $t('accountPage.details.title') }}</v-toolbar-title>
      <v-spacer />
      <v-btn
        color="white"
        icon
        @click="$emit('edit')"
      >
        <v-icon>edit</v-icon>
      </v-btn>
      <v-btn
        v-if="isAdminUser()"
        color="white"
        icon
        @click="$emit('delete')"
      >
        <v-icon>delete</v-icon>
      </v-btn>
    </v-toolbar>
    <v-form
      ref="form"
      :class="$style.form"
    >
      <v-container>
        <v-row>
          <v-col v-for="field in accountFields"
                 :key="field.id"
                 class="p-1 rows"
                 :cols="field.width"
          >
            <v-text-field
              :label="field.description"
              :value="getValue(field)"
              color="grey darken-1"
              readonly
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>
<script>
import accountFieldsService from '../../js/services/accountFieldsService';
import userService from '../../js/services/userService';

export default {
  name:  'AccountDetails',
  props: {
    accountValues: {
      type:    Object,
      default: () => {},
    },
  },
  data() {
    return {
      accountFields: [],
    };
  },
  async mounted() {
    await accountFieldsService.loadAccountFields();

    this.accountFields = accountFieldsService.getAccountFields();
  },
  methods: {
    /**
     * Returns true if the user is admin
     *
     * @return {boolean}
     */
    isAdminUser() {
      return userService.isAdmin();
    },

    getValue(field) {
      if (field.type === 2) {
        if (this.accountValues[field.key] === '1') {
          return this.$t('accountPage.gender[0]');
        }

        if (this.accountValues[field.key] === '2') {
          return this.$t('accountPage.gender[1]');
        }

        if (this.accountValues[field.key] === '3') {
          return this.$t('accountPage.gender[2]');
        }

        if (this.accountValues[field.key] === '4') {
          return this.$t('accountPage.gender[3]');
        }
      }

      return this.accountValues && this.accountValues[field.key] ? this.accountValues[field.key] : ' ';
    },
  },
};
</script>
<style lang="scss" module>
@import '../../css/variables';

.title {
  background-color: $blue-title-2;
  color: #FFFFFF;
}

.form {
  padding: 0px 20px;
}
</style>
