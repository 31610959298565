<template>
  <v-dialog v-model="dialog"
            persistent
            max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ $t('capturePage.select.dialog.title') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-text-field v-model="selectFrom"
                            :label="$t('capturePage.select.dialog.from')"
                            :class="$style.fields"
                            color="grey darken-1"
                            type="number"
                            min="1"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="selectTo"
                            :label="$t('capturePage.select.dialog.to')"
                            :class="$style.fields"
                            color="grey darken-1"
                            type="number"
                            min="1"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="selectEvery"
                            :label="$t('capturePage.select.dialog.every')"
                            :class="$style.fields"
                            color="grey darken-1"
                            type="number"
                            min="1"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1"
               text
               @click="closeDialog"
        >
          {{ $t('capturePage.select.dialog.accept') }}
        </v-btn>
        <v-btn color="blue darken-1"
               text
               @click="cancelDialog"
        >
          {{ $t('capturePage.select.dialog.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name:  'CaptureDetailsSelectDialog',
  props: {
    value: {
      type:    Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      dialog:      false,
      selectFrom:  1,
      selectTo:    1,
      selectEvery: 1,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;

      this.$emit('close', this.selectFrom, this.selectTo, this.selectEvery);
      this.selectFrom = 1;
      this.selectTo = 1;
      this.selectEvery = 1;
    },
    cancelDialog() {
      this.dialog = false;
      this.$emit('cancel');
      this.selectFrom = 1;
      this.selectTo = 1;
      this.selectEvery = 1;
    },
  },
};

</script>

<style lang="scss" module>

  .fields {
    margin-bottom: 0px;
  }
</style>
