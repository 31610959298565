import Vue from 'vue';
import { mapMutations, mapGetters } from 'vuex';
import store from '../store/store';
import { ACCOUNT_FIELDS_MUTATION } from '../store/mutations';
import { ACCOUNT_FIELDS_GETTER } from '../store/getters';

export default {
  $store: store,

  /**
   * Mutations from Vuex Store
   */
  ...mapMutations([ACCOUNT_FIELDS_MUTATION]),

  /**
   * Getters from Vuex Storage
   */
  ...mapGetters([ACCOUNT_FIELDS_GETTER]),

  /**
   * Load account fields from api.
   */
  async loadAccountFields() {
    try {
      const response = await Vue.axios.get('/api/account_fields');

      this[ACCOUNT_FIELDS_MUTATION](response.data);
    } catch (err) {
      // console.log(`loadAccountFields ${err}`);

      this[ACCOUNT_FIELDS_MUTATION]([]);
    }
  },

  /**
   * Get all account fields.
   *
   * @return {Array}
   */
  getAccountFields() {
    return this[ACCOUNT_FIELDS_GETTER]().filter(field => field.visible);
  },

  /**
   * Get all account fields sorting by 'permanant' flag.
   *
   * @return {Array}
   */
  getSortingAccountFields() {
    return this.getPermanentAccountFields().concat(
      this.getNotPermanentAccountFields(),
    );
  },

  /**
   * Get only permanent account fields.
   *
   * @return {Array}
   */
  getPermanentAccountFields() {
    return this.getAccountFields().filter(field => field.permanant);
  },

  /**
   * Get only not permanent account fields.
   *
   * @return {Array}
   */
  getNotPermanentAccountFields() {
    return this.getAccountFields().filter(field => !field.permanant);
  },

  /**
   * Get all account data fields.
   *
   * @return {Promise}
   */
  getAllAccountFields() {
    return Vue.axios
      .get('/api/account_fields')
      .then(response => response.data)
      .catch(error => {
        // console.log(`getAllAccountFields ${error}`);
        throw error.response;
      });
  },

  /**
   * Create new account data field.
   *
   * @param {string} data.key - Account data field key.
   * @param {string} data.description - Account data field description.
   * @param {integer} data.sort_order - Account data field sort order.
   * @param {integer} data.visible - Visible or not visible account data field.
   * @param {integer} data.permanant - Permanent or not permanent account data field.
   *
   * @return {Object}
   */
  addAccountField(data) {
    return Vue.axios
      .post('/api/account_fields', data)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Delete account data field.
   *
   * @param {integer} accountFieldID - Account data field identificator.
   *
   * @returns {Promise}
   */
  deleteAccountField(accountFieldID) {
    return Vue.axios
      .delete(`/api/account_fields/${accountFieldID}`)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Update account data field.
   *
   * @param {string} data.key - Account data field key.
   * @param {string} data.description - Account data field description.
   * @param {integer} data.sort_order - Account data field description.
   * @param {integer} data.visible - Visible or not visible account data field.
   * @param {integer} data.permanant - Permanent or not permanent account data field.
   *
   * @returns {Promise}
   */
  updateAccountField(data) {
    return Vue.axios
      .put('/api/account_fields', data)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },
};
