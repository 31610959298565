import { render, staticRenderFns } from "./Captures.vue?vue&type=template&id=41dfc126&scoped=true"
import script from "./Captures.vue?vue&type=script&lang=js"
export * from "./Captures.vue?vue&type=script&lang=js"
import style0 from "./Captures.vue?vue&type=style&index=0&id=41dfc126&prod&lang=scss&module=true"
import style1 from "./Captures.vue?vue&type=style&index=1&id=41dfc126&prod&lang=scss&scoped=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "41dfc126",
  null
  
)

export default component.exports