<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    @click:outside="close()"
  >
    <v-card>
      <v-card-title class="text-h6 white--text primary">
        {{ $t('capturePage.captureReassignTitle') }}
        <v-spacer />
        <v-btn
          icon
          dark
          @click="close()"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-text-field
            :value="capture.account_name"
            :label="$t('capturePage.currentAccount')"
            readonly
            class="pt-6"
          />
        </v-row>
        <v-row>
          <v-autocomplete
            v-model="selectedAccount"
            :items="accountNames"
            :search-input.sync="searchText"
            :loading="isLoading"
            solo-inverted
            :label="$t('capturePage.newAccount')"
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="!selectedAccount"
          color="secondary white--text v-btn"
          @click="save()"
        >
          {{ $t('globalActions.save') }}
        </v-btn>
        <v-btn
          color="gray darken-3"
          @click="close()"
        >
          {{ $t('globalActions.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import capturesService from '../../js/services/capturesService';
import accountsService from '../../js/services/accountsService';

export default {
  name:  'CaptureReassign',
  props: {
    capture: {
      type:    Object,
      default: () => {},
    },
    showDialog: {
      type:    Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog:          false,
      selectedAccount: '',
      accountNames:    [],
      searchText:      '',
      isLoading:       false,
    };
  },
  watch: {
    /**
     * Search account's names for autocomplete.
     */
    searchText: {
      async handler() {
        this.accountNames = await accountsService.loadAccountNames(this.searchText);
      },
    },
    showDialog(val) {
      this.dialog = val;
    },
  },
  methods: {
    async save() {
      if (!this.selectedAccount) {
        return;
      }

      const captureUpdate = {
        id:         this.capture.id,
        state:      this.capture.state,
        account_id: this.selectedAccount,
      };

      const updateData = await capturesService.update(captureUpdate);

      if (updateData) {
        this.$emit('ok');
      } else {
        this.$emit('error');
      }

      this.dialog = false;
    },
    close() {
      this.dialog = false;
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
  @import '../../css/variables';

  .title {
    background-color: $blue-title;
  }
</style>
