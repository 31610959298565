<template>
  <div
    :class="$style.root"
  >
    <v-row no-gutters
           class="align-center"
    >
      <v-col
        v-if="!isAvatar"
        class="d-flex flex-row align-center"
      >
        <span class="text-h6 mx-3"
              :class="$style.lowerHeader"
        >
          {{ $t('capturePage.select3D.optionsTitle') }}
        </span>
        <v-radio-group
          v-model="renderMode"
          row
          class="pt-2 mx-3"
          :column="false"
        >
          <v-radio
            v-for="option in renderModes"
            :key="option.id"
            :label="option.name"
            :value="option.id"
            color="primary"
          />
        </v-radio-group>
      </v-col>
      <v-spacer />
      <v-col class="d-flex justify-end mr-3 py-2">
        <v-btn
          @click="$emit('export')"
        >
          {{ $t('capturePage.select3D.exportModel') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import capturesService from '../../js/services/capturesService';

export default {
  name:  'CaptureDetails3DSelect',
  props: {
    value: {
      type:    String,
      default: capturesService.renderModes.grey,
    },
    isAvatar: {
      type:    Boolean,
      default: false,
    },
  },
  data() {
    return {
      renderMode:  capturesService.renderModes.grey,
      renderModes: [
        {
          name: this.$t('capturePage.select3D.options.color'),
          id:   capturesService.renderModes.color,
        },
        {
          name: this.$t('capturePage.select3D.options.grey'),
          id:   capturesService.renderModes.grey,
        },
        {
          name: this.$t('capturePage.select3D.options.wireframe'),
          id:   capturesService.renderModes.wireframe,
        },
      ],
    };
  },
  watch: {
    renderMode(newVal) {
      this.$emit('render-mode-changed', newVal);
    },
    value(newVal) {
      this.renderMode = newVal;
    },
  },
};
</script>
<style lang="scss" module>
  @import '../../css/variables';

  .root {
    background-color: $grey-1;
  }

  .lowerHeader {
    white-space:nowrap;
  }

</style>
