import Vue from 'vue';
import { mapMutations, mapGetters } from 'vuex';
import sha512 from 'sha512';
import store from '../store/store';
import userService from './userService';
import { SETTINGS_PAGE } from '../router/pages';
import {
  LOGIN_MUTATION,
  LOGOUT_MUTATION,
  USER_MUTATION,
  CLEAR_IMAGE_STUDIO_MUTATION,
  SET_LOGIN_REDIRECT_ROUTE,
  CLEAR_LOGIN_REDIRECT_ROUTE,
  BREADCRUMBS_PARAMS_MUTATION,
  SET_DERMAID_ENABLED,
} from '../store/mutations';
import {
  AUTHENTICATED_GETTER,
  AUTH_USER_GETTER,
  LOGIN_REDIRECT_ROUTE,
} from '../store/getters';

export default {
  $store: store,

  /**
   * Mutations from Vuex Store.
   */
  ...mapMutations([
    LOGIN_MUTATION,
    LOGOUT_MUTATION,
    USER_MUTATION,
    CLEAR_IMAGE_STUDIO_MUTATION,
    SET_LOGIN_REDIRECT_ROUTE,
    CLEAR_LOGIN_REDIRECT_ROUTE,
    BREADCRUMBS_PARAMS_MUTATION,
    SET_DERMAID_ENABLED,
  ]),

  /**
   * Getters from Vuex Storage.
   */
  ...mapGetters([
    AUTHENTICATED_GETTER,
    AUTH_USER_GETTER,
    LOGIN_REDIRECT_ROUTE,
  ]),

  /**
   * Get image from api and convert it to base64.
   *
   * @param {string} imagePath - URL to the user's picture
   *
   * @return {string}
   */
  getImage(imagePath) {
    return Vue.axios
      .get(imagePath, {
        headers:      { 'Cache-Control': 'no-cache' },
        responseType: 'arraybuffer',
      })
      .then(response => {
        const image = Buffer.from(response.data, 'binary').toString('base64');

        return `data:${response.headers[
          'content-type'
        ].toLowerCase()};base64,${image}`;
      })
      .catch(error => {
        // console.log(`getImage authService ${error}`);

        throw error.response;
      });
  },

  /**
   * Get user profile.
   *
   * @params {Integer} userID - User identificator.
   *
   * @return {Promise}
   */
  async getUserData(userID) {
    return Vue.axios
      .get(`/api/users/${userID}`)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Login user.
   *
   * @param {string} username - User's login.
   * @param {string} password - User's password.
   */
  async login(username, password) {
    try {
      const response = await Vue.axios.post('/api/auth/login', {
        username,
        password: sha512(password).toString('hex'),
      });

      this.setIsAuthenticated();

      const userData = await this.getUserData(response.data.id);

      this[USER_MUTATION](userData);

      this.getImage(`api/users/${response.data.id}/image`)
        .then(res => {
          const user = { ...userData, image: res };

          this[USER_MUTATION](user);
        })
        .catch(() => {});

      return this[LOGIN_REDIRECT_ROUTE]();
    } catch (error) {
      throw error.response;
    }
  },

  /**
   * Login user.
   *
   * @param {string} username - User's login.
   * @param {string} password - User's password.
   */

  /**
   * User logout.
   *
   * @return {void}
   */
  async logout() {
    try {
      await Vue.axios.post('/api/auth/logout');
    } catch (error) {
      throw error.response;
    } finally {
      this[USER_MUTATION]();
      this[CLEAR_IMAGE_STUDIO_MUTATION]();
      this[BREADCRUMBS_PARAMS_MUTATION]({});
      this[LOGOUT_MUTATION]();
    }
  },

  /**
   * Update auth token.
   *
   * @params {string} authToken - Api auth token.
   */
  setIsAuthenticated() {
    this[LOGIN_MUTATION]();
  },

  /**
   * Returns TRUE if the current user is authenticated.
   *
   * @return {boolean}
   */
  isAuthenticated() {
    return this[AUTHENTICATED_GETTER]();
  },

  /**
   * Returns true if logged user has access to the page.
   *
   * @param {Route} route - The target Route Object being navigated to.
   *
   * @return {boolean}
   */
  checkAccess(route) {
    switch (route.name) {
      case SETTINGS_PAGE:
        return userService.isAdmin();

      default:
        return false;
    }
  },

  /**
   * Save referrer route before redirect to login page.
   *
   * @param {Object|null} route - Current route.
   */
  saveLoginRedirectRoute(route) {
    this[SET_LOGIN_REDIRECT_ROUTE](route);
  },

  /**
   * Clear referrer route.
   */
  clearLoginRedirectRoute() {
    this[CLEAR_LOGIN_REDIRECT_ROUTE]();
  },

};
