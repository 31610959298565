/* eslint-disable camelcase */
import {
  required, email, max, min, image, confirmed, alpha_num, max_value,
  min_value,
} from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('required', required);
extend('email', email);
extend('max', max);
extend('min', min);
extend('image', image);
extend('confirmed', confirmed);
extend('alpha_num', alpha_num);
extend('max_value', max_value);
extend('min_value', min_value);
