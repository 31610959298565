<template>
  <v-container class="pt-0 px-12">
    <v-row>
      <v-col>
        <h1 class="subHeader">
          {{ $t('videoStudioReviewPage.title') }}
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9" />
      <v-col>
        <v-btn
          class="mx-2"
          @click="infoDialog=true"
        >
          {{ $t('videoStudioReviewPage.info') }}
        </v-btn>
        <v-btn
          class="mx-2"
          color="secondary"
          @click="exportDialog=true"
        >
          {{ $t('videoStudioReviewPage.download') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="exportDialog"
      persistent
      max-width="290"
    >
      <v-card :loading="exportLoading">
        <v-card-title class="text-h6 white--text primary">
          {{ $t('imageStudio.exportDialog.title') }}
        </v-card-title>
        <v-card-text>
          <input v-model="fileName"
                 style="padding: 10px;
                  border: 1px solid;
                  margin-top: 12px;
                  width: 100%;"
                 type="text"
          >
          <v-alert v-if="exportError"
                   type="error"
          >
            {{ $t('imageStudio.exportDialog.error') }}
          </v-alert>
          <v-btn
            class="ma-2"
            :disabled="exportLoading!==false"
            @click="downloadVideo()"
          >
            Export
          </v-btn>
          <v-btn
            class="ma-2"
            :disabled="exportLoading!==false"
            @click="exportDialog=false"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <v-row class="justify-center">
          <!--<v-btn icon>
            <v-icon>
              cloud_download
            </v-icon>
          </v-btn>-->
          <video
            width="75%"
            height="auto%"
            controls
            disablePictureInPicture
            controlsList="nodownload"
            :src="videoUrl"
            crossorigin="use-credentials"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="infoDialog"
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-h6 white--text primary">
          {{ $t('videoStudioReviewPage.infoTitle') }}
          <v-spacer />
          <v-btn
            icon
            dark
            @click="close()"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="currentTab">
            <v-tab
              v-for="(tab, index) in tabs"
              :key="index"
              :href="`#tab-${index}`"
            >
              {{ tab }}
            </v-tab>
            <v-tab-item v-for="(tab, index) in tabs"
                        :key="index"
                        :value="`tab-${index}`"
            >
              <v-card flat
                      tile
              >
                <v-card-text v-if="index === 0">
                  <v-text-field
                    :value="exportType"
                    :label="$t('videoStudioReviewPage.exportType')"
                    readonly
                  />
                </v-card-text>
                <v-card-text v-else>
                  <v-list-item-group
                    color="primary"
                  >
                    <v-list>
                      <v-list-item
                        v-for="(playlist, idx) in exportInfo.playlists[index-1]"
                        :key="idx"
                        @click="gotoCapture(index-1, idx)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ captureInfo(index-1, idx) }}
                            <v-list-item-title />
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-list-item-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { saveAs } from 'file-saver/FileSaver';
import videoStudioService from '../js/services/videoStudioService';
import imageStudioService from '../js/services/imageStudioService';
import { CAPTURE_PAGE } from '../js/router/pages';

export default {
  name: 'VideoStudioReview',
  data() {
    return {
      exportNotFound: false,
      exportInfo:     {},
      videoInfo:      {},
      videoUrl:       '',
      infoDialog:     false,
      currentTab:     null,
      tabs:           [],
      exportDialog:   false,
      exportLoading:  false,
      exportError:    false,
      fileName:       '',
    };
  },
  computed: {
    exportType() {
      if (this.exportInfo === null) {
        return this.$t('videoStudioReviewPage.exportTypeUnknown');
      }

      switch (this.exportInfo.export_type) {
        case 1:
          return this.$t('videoStudioReviewPage.exportTypeComparison');

        case 2:
          return this.$t('videoStudioReviewPage.exportTypePlaylist');

        default:
          return this.$t('videoStudioReviewPage.exportTypeUnknown');
      }
    },
  },
  watch: {
    async exportDialog() {
      const { videoStudioId } = this.$route.params;

      const videoInfo = await videoStudioService.getVideoInfo(videoStudioId);
      const captureIds = videoInfo.playlists[0].map(vid => vid.capture_id);

      const response = await imageStudioService
        .getDownloadInfo(
          captureIds,
          '.mp4',
        );

      this.fileName = response.filename;
    },
  },
  async mounted() {
    await this.loadVideo();

    this.tabs.push(this.$t('videoStudioReviewPage.videoInfoTab'));

    this.exportInfo.playlists.forEach((setting, index) => {
      this.tabs.push(`${this.$t('videoStudioReviewPage.playlist')} ${index}`);
    });
  },
  methods: {
    async loadVideo() {
      try {
        this.videoUrl = await videoStudioService.getVideoUrl(this.$route.params.videoStudioId);

        this.exportInfo = await videoStudioService.getVideoInfo(this.$route.params.videoStudioId);
      } catch (error) {
        this.exportNotFound = true;
      }
    },
    captureInfo(playlistId, index) {
      return this.exportInfo.playlists[playlistId][index].capture_id;
    },
    gotoCapture(playlistId, index) {
      this.$router.push({
        name:   CAPTURE_PAGE,
        params: {
          captureId: this.exportInfo.playlists[playlistId][index].capture_id,
        },
      });
    },
    close() {
      this.infoDialog = false;
      this.exportDialog = false;
    },
    async downloadVideo() {
      const model = await videoStudioService.downloadVideo(this.$route.params.videoStudioId);

      saveAs(new Blob([model], { type: 'video/mp4;charset=utf-8' }), `${this.fileName}`);
    },
    isValidFileName(filename) {
    // filename should not be empty
      if (!filename.trim()) {
        this.exportError = 'Filename cannot be empty';

        return false;
      }

      // filename should not be too long
      if (filename.length > 255) {
        this.exportError = 'Filename is too long';

        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/variables";

.subHeader {
  color: $grey-darken-1;
}
.title {
  background-color: $blue-title;
}

</style>
