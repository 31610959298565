import Vue from 'vue';

export default {
  /**
   * Get list of all tags.
   *
   * @return {Array}
   */
  async loadTags() {
    try {
      const response = await Vue.axios.get('/api/tags');

      return response.data;
    } catch (err) {
      // console.log(err);

      return [];
    }
  },

  /**
   * Get capture's tags.
   *
   * @param {number} data - Capture and Tag info.
   *
   * @return {Array}
   */
  async createCaptureTagAssociation(data) {
    return Vue.axios
      .post('/api/capture_tag_map/', data)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Delete capture's tags.
   *
   * @param {number} id - Capture identifier.
   *
   * @return {Object}
   */
  async deleteCaptureTagAssociation(delInfo) {
    const config = {
      data: delInfo,
    };

    return Vue.axios
      .delete('/api/capture_tag_map', config)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Update tag data.
   *
   * @params {Object} data - Array with tag data.
   *
   * @return {Promise}
   */
  updateTag(data) {
    return Vue.axios
      .put('/api/tags/', data)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Delete capture's tags.
   *
   * @param {number} id - Capture identifier.
   *
   * @return {Object}
   */
  async delete(tagId) {
    return Vue.axios
      .delete(`/api/tags/${tagId}`)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Create new tag.
   *
   * @params {Object} data - Array with tag data.
   *
   * @return {Promise}
   */
  async createTag(data) {
    return Vue.axios
      .post('/api/tags/', data)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },
};
