<template>
  <div ref="viewerContainer"
       :class="{ fullscreen: isFullscreen }"
       @mousedown="startDrag"
       @mousemove="onDrag"
       @mouseup="stopDrag"
       @mouseleave="stopDrag"
       @dblclick="resetPosition"
       @wheel="onScroll"
  >
    <img :src="imageSrc"
         alt="Project Image"
         class="responsive-image"
         :style="imageStyle"
    >
    <div class="zoom-controls">
      <v-btn icon
             color="white"
             @click="decreaseZoom"
      >
        <v-icon>remove</v-icon>
      </v-btn>
      <v-slider v-model="zoom"
                min="1"
                max="10"
                step="0.1"
                class="zoom-slider"
                color="white"
                @mousedown="disableDrag"
                @mouseup="enableDrag"
      />
      <v-btn icon
             color="white"
             @click="increaseZoom"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </div>
    <p class="zoom-note">
      Double click to center the image
    </p>
    <v-btn v-if="isFullscreen"
           class="exit-fullscreen-btn"
           color="transparent"
           @click="exitFullscreen"
    >
      <v-icon large
              color="white"
      >
        fullscreen_exit
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'FullScreenProjectViewer',

  props: {
    imageSrc: {
      type:    String,
      default: '',
    },
    isFullscreen: {
      type:    Boolean,
      default: false,
    },
  },
  data() {
    return {
      zoom:           1,
      isDragging:     false,
      dragEnabled:    true,
      startX:         0,
      startY:         0,
      offsetX:        0,
      offsetY:        0,
      dragStartX:     0,
      dragStartY:     0,
      initialZoom:    1,
      initialOffsetX: 0,
      initialOffsetY: 0,
    };
  },
  computed: {
    imageStyle() {
      return {
        transform: `scale(${this.zoom}) translate(${this.offsetX}px, ${this.offsetY}px)`,
      };
    },
  },
  watch: {
    zoom(newZoom, oldZoom) {
      const ratio = newZoom / oldZoom;

      this.offsetX *= ratio;
      this.offsetY *= ratio;
    },
  },
  mounted() {
    if (this.isFullscreen) {
      document.addEventListener('keydown', this.handleKeydown);
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    enterFullscreen() {
      const element = this.$refs.viewerContainer;

      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
      this.$emit('update:isFullscreen', true);
      document.addEventListener('keydown', this.handleKeydown);
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      this.$emit('update:isFullscreen', false);
      document.removeEventListener('keydown', this.handleKeydown);
    },
    handleKeydown(event) {
      if (event.key === 'Escape') {
        this.exitFullscreen();
      }
    },
    startDrag(event) {
      if (!this.dragEnabled) return;
      this.isDragging = true;
      this.dragStartX = event.clientX;
      this.dragStartY = event.clientY;
      event.preventDefault();
    },
    onDrag(event) {
      if (this.isDragging && this.dragEnabled) {
        const dx = (event.clientX - this.dragStartX) / this.zoom;
        const dy = (event.clientY - this.dragStartY) / this.zoom;

        this.offsetX += dx;
        this.offsetY += dy;
        this.dragStartX = event.clientX;
        this.dragStartY = event.clientY;
      }
    },
    stopDrag() {
      this.isDragging = false;
    },
    resetPosition() {
      this.offsetX = 0;
      this.offsetY = 0;
    },
    onScroll(event) {
      const zoomStep = 0.1;

      if (event.deltaY < 0) {
        this.zoom = Math.min(this.zoom + zoomStep, 10);
      } else {
        this.zoom = Math.max(this.zoom - zoomStep, 1);
      }
      event.preventDefault();
    },
    increaseZoom() {
      this.zoom = Math.min(this.zoom + 0.1, 10);
    },
    decreaseZoom() {
      this.zoom = Math.max(this.zoom - 0.1, 1);
    },
    disableDrag() {
      this.dragEnabled = false;
    },
    enableDrag() {
      this.dragEnabled = true;
    },
  },
};
</script>

<style scoped>
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.responsive-image {
  width: 50%;
  height: auto;
  transition: transform 0.2s ease;
  cursor: grab;
  top: 0;
  left: 0;
}
.responsive-image:active {
  cursor: grabbing;
}
.zoom-controls {
  position: fixed;
  bottom: 10%;
  left: 10%;
  display: flex;
  align-items: center;
}
.zoom-slider {
  width: 200px;
}
.zoom-note {
  position: fixed;
  bottom: 8%;
  left: 50%;
  color: white;
  font-size: 12px;
  transform: translateX(-50%);
}
.exit-fullscreen-btn {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
  color: white;
}
</style>
